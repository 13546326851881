/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import MoneyInput from 'components/inputs/MoneyInput';
import { useData } from '../../../providers/DataProvider';

/**
 * Input inside a CostEstimateRow to specify a price.
 *
 * @param {{
 *   data: CostEstimateRowData,
 * }}
 */
export default function Price({ data }) {
  const { data: costEstimateData } = useData();

  return <MoneyInput disabled value={data.price} currency={costEstimateData.currency} />;
}
