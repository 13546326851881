/** @typedef {import('api/actions/time-log-get-internal-costs-summary-action/time-log-get-internal-costs-summary-action-response').TimeLogGetInternalCostsSummaryActionResponse['groups'][number]} SummaryGroup */

import { Divider, Stack } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { uniq } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import HoursSummaryJobPosition from './HoursSummaryJobPosition';
import { useCostEstimatePartData } from '../part/CostEstimatePart';
import { useData } from '../../providers/DataProvider';

/** @type {SummaryGroup[]} */
const emptyGroups = [];

/**
 * A summary of hours per position.
 */
export default function HoursSummary() {
  const { getAction } = useApi();
  const [groups, setGroups] = useState(emptyGroups);
  const { data } = useCostEstimatePartData();
  const { data: costEstimateData } = useData();

  const tasks = useMemo(
    () => uniq(data.projects.filter(({ allTasks }) => !allTasks).flatMap(({ tasks }) => tasks)),
    [data.projects]
  );

  const projects = useMemo(
    () => data.projects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId),
    [data.projects]
  );

  useEffect(() => {
    // TODO: Consider positions in CE but not in time logs.

    const getSummary = getAction('TimeLogGetInternalCostsSummaryAction');

    getSummary({ query: { tasks, projects } })
      .then(({ groups }) => setGroups(groups))
      .catch(panic);

    // Refresh project data when the cost estimate is updated.
  }, [tasks, projects, costEstimateData.updatedAt]);

  return (
    <Stack spacing={0} px={40} pt={16}>
      {groups.length === 0 && (
        <p className="py-4">{_t('There are no time entries associated with the selected projects at present.')}</p>
      )}
      {groups.length > 0 && (
        <div className="grid grid-cols-[1fr_112px_112px_112px] items-center gap-4 pr-8">
          <span className="text-[12px] leading-[16px] text-neutral-500">{_t('Job position')}</span>
          <span className="text-right text-[12px] leading-[16px] text-neutral-500">{_t('Hours in CE')}</span>
          <span className="text-right text-[12px] leading-[16px] text-neutral-500">{_t('Worked hours')}</span>
          <span className="text-right text-[12px] leading-[16px] text-neutral-500">{_t('% spent')}</span>
        </div>
      )}
      {groups.map(({ position_in_company_id, position_name, total_hours }) => (
        <Fragment key={position_in_company_id}>
          <HoursSummaryJobPosition
            data={data}
            name={position_name}
            jobPositionId={position_in_company_id}
            spentHours={total_hours}
          />
          <Divider color="neutral100" />
        </Fragment>
      ))}
    </Stack>
  );
}
