import { Collapse, Stack } from '@mantine/core';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import FormRow from 'components/forms/FormRow';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useCallback, useState } from 'react';
import { useData } from '../../providers/DataProvider';

/**
 * The details section of a cost estimate.
 */
export default function DetailsSection() {
  const { data } = useData();
  const [opened, setOpened] = useState(true);

  const formatDate = useCallback(
    (date) =>
      new Date(date).toLocaleString('sk-SK', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    []
  );

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('DETAILS')} opened={opened} setOpened={setOpened} />
      <Collapse in={opened}>
        <Stack spacing={16} maw={790}>
          <FormRow label={_t('Billing type')} input={_t('Project or task')} />
          <FormRow label={_t('Author')} input={<UserNameWithAvatar name={data.createdBy} />} />
          <FormRow label={_t('Created')} input={formatDate(data.createdAt)} />
          <FormRow label={_t('Last update by')} input={<UserNameWithAvatar name={data.updatedBy} />} />
          <FormRow label={_t('Last update')} input={formatDate(data.updatedAt)} />
        </Stack>
      </Collapse>
    </Stack>
  );
}
