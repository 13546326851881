/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { NumberInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { isNumber, round } from 'lodash';

/**
 * Input inside a CostEstimateRow to specify a number.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function Number({ data, updateData }) {
  const [number, setNumber] = useState(data.number);

  const precision = data.unit === 'pcs' ? 0 : 2;
  const step = data.unit === 'pcs' ? 1 : 0.25;

  // Round to precision.
  useEffect(() => {
    setNumber((number) => (isNumber(number) ? round(number, precision) : undefined));
  }, [precision]);

  // Propagate changes with debounce.
  useEffect(() => {
    updateData({ number });
  }, [number]);

  return (
    <NumberInput
      hideControls
      min={0}
      precision={precision}
      step={step}
      decimalSeparator=","
      value={number}
      onChange={setNumber}
      styles={{ input: { textAlign: 'right' } }}
    />
  );
}
