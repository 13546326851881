/** @typedef {import('../add-role/AddRolePage').RoleFormData['permissions']} IPermissions */
/** @typedef {import('components/forms/role-form/RoleForm').RoleFormData} RoleFormData */

import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useParams } from 'react-router-dom';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import RoleForm from 'components/forms/role-form/RoleForm';
import { isEmpty } from 'lodash';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditRolePage.
 *
 * @param {{ roleId: string }}
 */
function EditRoleImpl({ roleId }) {
  const { getAction } = useApi();
  const { refreshRoles } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const roleGetListAction = getAction('RoleGetListAction');

    setLoading(true);

    roleGetListAction({
      query: {
        filter: { 'role_id.eq': roleId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }

        const role = data[0];
        const rawMembers = role.members.map((member) => String(member.user_id));

        /** Returns the permission slug for the given client slug */
        const getPermissionSlug = (clientSlug) => {
          const globalSlug = `${clientSlug}_GLOBAL`;
          const clientPermission = role.permissions.find(({ permission_slug }) => permission_slug === clientSlug);
          const globalPermission = role.permissions.find(({ permission_slug }) => permission_slug === globalSlug);

          return globalPermission?.permission_slug || clientPermission?.permission_slug || null;
        };

        /** @type {IPermissions} */
        const permissions = {
          viewProjects: getPermissionSlug('PROJECTS_VIEW_PROJECT'),
          createTimeLogs: getPermissionSlug('TIME_LOGS_CREATE_TIME_LOG'),
          createComments: getPermissionSlug('COMMENTS_CREATE_COMMENT'),
          uploadFiles: getPermissionSlug('PROJECTS_UPLOAD_FILES'),
          manageOthersComments: getPermissionSlug('COMMENTS_MANAGE_COMMENT'),
          manageOthersTimeLogs: getPermissionSlug('TIME_LOGS_MANAGE_TIME_LOG'),
          manageProjects: getPermissionSlug('PROJECTS_MANAGE_PROJECT'),
          manageFinance: getPermissionSlug('FINANCE_MANAGE_FINANCE'),
          manageSettings: getPermissionSlug('SETTINGS_MANAGE_SETTINGS'),
          manageRoles: getPermissionSlug('SETTINGS_MANAGE_ROLE'),
        };

        const initialValues = {
          roleName: role.role_name,
          description: role.description,
          permissions,
          members: rawMembers,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, roleId]);

  if (!roleId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Sends the request to the API and update the role
   *
   * @param {RoleFormData}
   */
  const submit = ({ roleName, description, permissions }) => {
    const roleUpdateAction = getAction('RoleUpdateAction');

    const permissionSlugs = Object.values(permissions).filter((slug) => slug !== null);

    return roleUpdateAction({
      parameters: { role_id: roleId },
      body: {
        role_name: roleName,
        description,
        permissions: permissionSlugs,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Role updated'),
          message: _t("Role '%s' has been updated successfully.", `${roleName}`),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshRoles();
      })
      .catch(panic);
  };

  return (
    <>
      <PageHeading heading={_t('Edit role')} />
      {!loading && <RoleForm initialValues={initialValues} onSubmit={submit} />}
    </>
  );
}

/**
 * Displays a form to edit a role.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A25345
 */
export default function EditRolePage() {
  const { roleId } = useParams();

  return (
    <DashboardLayout
      contentClass="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0"
      sidebar={<SettingsSidebar />}
    >
      <EditRoleImpl key={roleId} roleId={roleId} />
    </DashboardLayout>
  );
}
