import { Stack } from '@mantine/core';
import Alert from 'components/Alert';
import { useClient } from 'providers/client/ClientProvider';

/**
 * Client note section.
 */
export default function ClientNoteSection() {
  const { client } = useClient();

  if (!client?.note_cost_estimate) {
    return null;
  }

  return (
    <Stack pt={24} pb={16}>
      <Alert severity="notification" primaryText={client.note_cost_estimate} />
    </Stack>
  );
}
