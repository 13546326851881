import { round } from 'lodash';

/** @typedef {'pcs' | 'hrs'} CostEstimateRowUnit */

/**
 * The unique identifier of a custom job position item.
 */
export const CUSTOM_JOB_POSITION_ITEM_ID = -1;

/**
 * Creates a new instance of CostEstimateRowData.
 *
 * @param {{
 *   jobPositionId: number,
 *   jobPositionName: string,
 *   number: number,
 *   unitPrice: number,
 *   unitCost: number,
 *   unit: CostEstimateRowUnit,
 *   note: string,
 *   externalCosts: number,
 *   comment: string | null,
 *   isInHouse: boolean,
 * }}
 */
export function createCostEstimateRowData({
  jobPositionId,
  jobPositionName = '',
  number,
  unitPrice,
  unitCost,
  unit = 'hrs',
  note = '',
  externalCosts = 0,
  comment = null,
  isInHouse = true,
} = {}) {
  let price = 0;
  let cost = 0;

  if (number && unitPrice) {
    price = round(number * unitPrice, 2);
  }

  if (number && unitCost) {
    cost = round(number * unitCost, 2);
  }

  const isCustomItem = jobPositionId === CUSTOM_JOB_POSITION_ITEM_ID;

  return Object.freeze({
    jobPositionId,
    jobPositionName,
    number,
    unitPrice,
    unitCost,
    unit,
    note,
    price,
    cost,
    externalCosts,
    comment,
    isInHouse,
    isCustomItem,
  });
}

/** @typedef {ReturnType<typeof createCostEstimateRowData>} CostEstimateRowData */
