import { Flex, Group } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import PriceDisplay from 'components/PriceDisplay';
import panic from 'errors/Panic';
import { uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useData } from '../../providers/DataProvider';
import ProgressGroup from 'components/progress/ProgressGroup';
import { _t } from 'lang';
import { useCostEstimatePartData } from './CostEstimatePart';

/**
 * A component that provides an overview of internal costs.
 */
export default function InternalCostOverview() {
  const { getAction } = useApi();
  const { data } = useCostEstimatePartData();
  const [actualCost, setActualCost] = useState(0);

  const tasks = useMemo(
    () => uniq(data.projects.filter(({ allTasks }) => !allTasks).flatMap(({ tasks }) => tasks)),
    [data.projects]
  );

  const projects = useMemo(
    () => data.projects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId),
    [data.projects]
  );

  const {
    data: { currency },
  } = useData();

  useEffect(() => {
    const getSummary = getAction('TimeLogGetInternalCostsSummaryAction');

    getSummary({ query: { tasks, projects } })
      .then(({ total_cost }) => setActualCost(total_cost))
      .catch(panic);
  }, [tasks, projects]);

  return (
    <Group
      h={68}
      align="center"
      className="grid grid-cols-[1fr_140px_140px_256px] items-center gap-8 rounded-lg bg-white"
      pr={72}
      pl={32}
    >
      <h3 className="text-[22px]">{_t('Internal cost overview')}</h3>
      <Flex direction="column" align="flex-end" w={144}>
        <small className="text-neutral-500">{_t('Allocated costs')}</small>
        <PriceDisplay value={data.internalCosts} currency={currency} />
      </Flex>
      <Flex direction="column" align="flex-end" w={144}>
        <small className="text-neutral-500">{_t('Actual utilization')}</small>
        <PriceDisplay value={actualCost} currency={currency} />
      </Flex>
      <ProgressGroup
        part={actualCost}
        total={data.internalCosts}
        variant="positive"
        currency={currency}
        barHeight={8}
      />
    </Group>
  );
}
