import { Button, Group } from '@mantine/core';
import { useData } from '../../providers/DataProvider';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import { IconArrowRight } from '@tabler/icons';
import { useSubmit } from '../../providers/SubmitProvider';

/**
 * Footer section of the add cost estimate page.
 */
export default function FooterSection() {
  const { hasChanges } = useData();
  const { save, isSaving } = useSubmit();

  return (
    <Group position="right" spacing={16} py={16}>
      <Button
        w={80}
        h={36}
        disabled={isSaving}
        variant="link"
        component={LinkWithConfirm}
        to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
        confirmTitle={_t('Cancel')}
        confirmMessage={_t('Are you sure you want to cancel? The changes will not be saved.')}
        skipConfirm={() => !hasChanges()}
      >
        {_t('Cancel')}
      </Button>
      <Button disabled={isSaving} variant="secondary" onClick={() => save({ isDraft: true })}>
        {_t('Save draft')}
      </Button>
      <Button
        disabled={isSaving}
        rightIcon={<IconArrowRight stroke="#fff" />}
        variant="primary"
        onClick={() => save({ isDraft: false })}
      >
        {_t('Send to confirm')}
      </Button>
    </Group>
  );
}
