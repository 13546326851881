import { useEffect, useMemo, useState } from 'react';
import { useData } from '../../providers/DataProvider';
import { uniq } from 'lodash';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';

/**
 * This hook is used to calculate the internal cost utilization.
 */
export default function useInternalCostUtilization() {
  const { getAction } = useApi();
  const { data } = useData();
  const [utilizedCost, setUtilizedCost] = useState(0);

  const allProjects = useMemo(() => data.collections.flatMap(({ projects }) => projects), [data.collections]);

  const tasks = useMemo(
    () => uniq(allProjects.filter(({ allTasks }) => !allTasks).flatMap(({ tasks }) => tasks)),
    [allProjects]
  );

  const projects = useMemo(
    () => allProjects.filter(({ allTasks }) => allTasks).map(({ projectId }) => projectId),
    [allProjects]
  );

  useEffect(() => {
    const getSummary = getAction('TimeLogGetInternalCostsSummaryAction');

    getSummary({ query: { tasks, projects } })
      .then(({ total_cost }) => setUtilizedCost(total_cost))
      .catch(panic);
  }, [tasks, projects]);

  return useMemo(() => {
    if (data.internalCosts === 0 && utilizedCost === 0) {
      return 0;
    }

    if (data.internalCosts === 0) {
      return Infinity;
    }

    return utilizedCost / data.internalCosts;
  }, [utilizedCost, data.internalCosts]);
}
