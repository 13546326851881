import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';
import FooterNoteSection from 'pages/finance/cost-estimate/add-cost-estimate/sections/footer-note/FooterNoteSection';
import GeneralInformationSection from 'pages/finance/cost-estimate/add-cost-estimate/sections/general-information/GeneralInformationSection';
import DetailsSection from 'pages/finance/cost-estimate/add-cost-estimate/sections/details/DetailsSection';
import CollectionsSection from './sections/collections/CollectionsSection';
import HeadingSection from './sections/heading/HeadingSection';
import ClientNoteSection from './sections/client-note/ClientNoteSection';
import DiscountSection from './sections/discount/DiscountSection';
import WarningsSection from './sections/warnings/WarningsSection';
import FooterSection from './sections/footer/FooterSection';
import FinancialOverviewSection from './sections/financial-overview/FinancialOverviewSection';
import useAutoSave from './hooks/use-auto-save';
import CommentsSection from './sections/comments/CommentsSection';

/**
 * Displays finance project cost estimate.
 */
export default function AddCostEstimatePageContent() {
  const { costEstimateId } = useParams();
  const { data } = useData();

  useAutoSave();

  // this is for edit only
  // data and costEstimateId are present
  // but the data does not have costEstimateId
  if (data && costEstimateId && !data.costEstimateId) {
    return <NotFoundPageContent />;
  }

  return (
    <Stack spacing={0}>
      <HeadingSection />
      <ClientNoteSection />

      <Stack spacing={16}>
        <GeneralInformationSection />
        <DetailsSection />
        <CollectionsSection />
        <DiscountSection />
        <FooterNoteSection />
        <FinancialOverviewSection />
        <WarningsSection />
        <CommentsSection />
        <FooterSection />
      </Stack>
    </Stack>
  );
}
