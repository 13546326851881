import { Group, Text } from '@mantine/core';
import { useData } from '../../providers/DataProvider';
import PriceDisplay from 'components/PriceDisplay';

/**
 * Table Row in the financial overview section.
 *
 * @param {{
 *   label: string,
 *   value: number,
 *   size?: 'md' | 'lg'
 * }}
 */
export default function FinancialOverviewRow({ label, value, size = 'md' }) {
  const { data } = useData();

  return (
    <Group h={48} spacing={16} position="apart" align="center" className="border-b border-b-neutral-100">
      <Text fz={size === 'lg' ? 22 : 16} lh={size === 'lg' ? 1.27 : 1.37}>
        {label}
      </Text>
      <PriceDisplay value={value} size={size} currency={data.currency} />
    </Group>
  );
}
