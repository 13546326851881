/** @generated */

/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/generate-environment.js. If you wish to
 * change the environment variables, modify the file config/environment.yaml and then run:
 *
 *     npm run generate:environment
 *
 * -------------------------------------------------------------------------------------------------
 */

import getenv from 'utils/getenv';

/**
 * Whether the feature is on.
 */
function isFeatureOn(feature) {
  return feature === 'on';
}

/**
 * The version of the application.
 */
export const VERSION =
  process.env.REACT_APP_VERSION ||
  getenv('VERSION', {
    development: 'local',
    test: 'local',
    production: '',
  });

/**
 * The interval for checking the version in ms.
 */
export const VERSION_CHECK_INTERVAL = Number(
  process.env.REACT_APP_VERSION_CHECK_INTERVAL ||
    getenv('VERSION_CHECK_INTERVAL', {
      development: 120000,
      test: 120000,
      production: '30000',
    })
);

/**
 * The feature toggle for showing the version.
 */
export const FEATURE_TOGGLE_SHOW_VERSION = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_SHOW_VERSION ||
    getenv('FEATURE_TOGGLE_SHOW_VERSION', {
      development: 'on',
      test: 'on',
      production: 'off',
    })
);

/**
 * The base url of the REST API.
 */
export const REST_API_URL =
  process.env.REACT_APP_REST_API_URL ||
  getenv('REST_API_URL', {
    development: '/',
    test: '/',
    production: '/',
  });

/**
 * The timeout for the REST API in ms.
 */
export const REST_API_TIMEOUT = Number(
  process.env.REACT_APP_REST_API_TIMEOUT ||
    getenv('REST_API_TIMEOUT', {
      development: 30000,
      test: 2000,
      production: '60000',
    })
);

/**
 * The base url of the file manager API.
 */
export const FILE_MANAGER_API_URL =
  process.env.REACT_APP_FILE_MANAGER_API_URL ||
  getenv('FILE_MANAGER_API_URL', {
    development: '/api/files',
    test: '/api/files',
    production: '/api/files',
  });

/**
 * The timeout for the file manager API in ms.
 */
export const FILE_MANAGER_API_TIMEOUT = Number(
  process.env.REACT_APP_FILE_MANAGER_API_TIMEOUT ||
    getenv('FILE_MANAGER_API_TIMEOUT', {
      development: 30000,
      test: 2000,
      production: '30000',
    })
);

/**
 * The maximum size of an image upload in bytes.
 */
export const IMAGE_UPLOAD_MAX_SIZE = Number(
  process.env.REACT_APP_IMAGE_UPLOAD_MAX_SIZE ||
    getenv('IMAGE_UPLOAD_MAX_SIZE', {
      development: 5242880,
      test: 5242880,
      production: '5242880',
    })
);

/**
 * The maximum size of a file upload in bytes.
 */
export const FILE_UPLOAD_MAX_SIZE = Number(
  process.env.REACT_APP_FILE_UPLOAD_MAX_SIZE ||
    getenv('FILE_UPLOAD_MAX_SIZE', {
      development: 15728640,
      test: 15728640,
      production: '15728640',
    })
);

/**
 * The size of a file upload chunk in bytes.
 */
export const FILE_UPLOAD_CHUNK_SIZE = Number(
  process.env.REACT_APP_FILE_UPLOAD_CHUNK_SIZE ||
    getenv('FILE_UPLOAD_CHUNK_SIZE', {
      development: 262144,
      test: 262144,
      production: '262144',
    })
);

/**
 * The minimal length of a search query.
 */
export const SEARCH_MINIMAL_LENGTH = Number(
  process.env.REACT_APP_SEARCH_MINIMAL_LENGTH ||
    getenv('SEARCH_MINIMAL_LENGTH', {
      development: 3,
      test: 3,
      production: '3',
    })
);

/**
 * The limit of the advanced search.
 */
export const ADVANCED_SEARCH_LIMIT = Number(
  process.env.REACT_APP_ADVANCED_SEARCH_LIMIT ||
    getenv('ADVANCED_SEARCH_LIMIT', {
      development: 10,
      test: 10,
      production: '10',
    })
);

/**
 * The interval for auto refreshing comments in ms.
 */
export const COMMENT_AUTO_REFRESH_INTERVAL = Number(
  process.env.REACT_APP_COMMENT_AUTO_REFRESH_INTERVAL ||
    getenv('COMMENT_AUTO_REFRESH_INTERVAL', {
      development: 10000,
      test: 10000,
      production: '10000',
    })
);

/**
 * The scroll behavior for opening the comment form.
 */
export const COMMENT_OPEN_FORM_SCROLL_BEHAVIOR =
  process.env.REACT_APP_COMMENT_OPEN_FORM_SCROLL_BEHAVIOR ||
  getenv('COMMENT_OPEN_FORM_SCROLL_BEHAVIOR', {
    development: 'smooth',
    test: 'smooth',
    production: 'instant',
  });

/**
 * The scroll behavior for focusing the reply.
 */
export const COMMENT_FOCUS_SCROLL_BEHAVIOR =
  process.env.REACT_APP_COMMENT_FOCUS_SCROLL_BEHAVIOR ||
  getenv('COMMENT_FOCUS_SCROLL_BEHAVIOR', {
    development: 'smooth',
    test: 'smooth',
    production: 'smooth',
  });

/**
 * The environment of the Toolio instance.
 */
export const TOOLIO_ENVIRONMENT =
  process.env.REACT_APP_TOOLIO_ENVIRONMENT ||
  getenv('TOOLIO_ENVIRONMENT', {
    development: 'development',
    test: 'test',
    production: '',
  });

/**
 * The feature toggle for the stopwatch.
 */
export const FEATURE_TOGGLE_STOPWATCH = isFeatureOn(
  process.env.REACT_APP_FEATURE_TOGGLE_STOPWATCH ||
    getenv('FEATURE_TOGGLE_STOPWATCH', {
      development: 'on',
      test: 'on',
      production: '',
    })
);

/**
 * The text to be displayed in the debug footer.
 */
export const DEBUG_FOOTER_TEXT =
  process.env.REACT_APP_DEBUG_FOOTER_TEXT ||
  getenv('DEBUG_FOOTER_TEXT', {
    development: 'This is the local development version.',
    test: '',
    production: '',
  });

/**
 * The website id of the Umami instance.
 */
export const UMAMI_WEBSITE_ID =
  process.env.REACT_APP_UMAMI_WEBSITE_ID ||
  getenv('UMAMI_WEBSITE_ID', {
    development: 'c3e9f0f0-0e4e-4f2a-8d8e-2e9b5b9e1c4b',
    test: '',
    production: '889d979a-f8e0-4fc7-aba6-e12fa05aa769',
  });

/**
 * The url of the Umami script.
 */
export const UMAMI_SCRIPT_URL =
  process.env.REACT_APP_UMAMI_SCRIPT_URL ||
  getenv('UMAMI_SCRIPT_URL', {
    development: '',
    test: '',
    production: 'https://umami.creanet.sk/script.js',
  });

/**
 * The API key for Firebase.
 */
export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  getenv('FIREBASE_API_KEY', {
    development: '',
    test: '',
    production: 'AIzaSyDUv36kQ6QsOh4SpsMxOkDjO4k2ynHg72c',
  });

/**
 * The auth domain for Firebase.
 */
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  getenv('FIREBASE_AUTH_DOMAIN', {
    development: '',
    test: '',
    production: 'toolio-oh-my-dot.firebaseapp.com',
  });

/**
 * The project id for Firebase.
 */
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID ||
  getenv('FIREBASE_PROJECT_ID', {
    development: '',
    test: '',
    production: 'toolio-oh-my-dot',
  });

/**
 * The storage bucket for Firebase.
 */
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  getenv('FIREBASE_STORAGE_BUCKET', {
    development: '',
    test: '',
    production: 'toolio-oh-my-dot.appspot.com',
  });

/**
 * The messaging sender id for Firebase.
 */
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  getenv('FIREBASE_MESSAGING_SENDER_ID', {
    development: '',
    test: '',
    production: '949766001903',
  });

/**
 * The app id for Firebase.
 */
export const FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID ||
  getenv('FIREBASE_APP_ID', {
    development: '',
    test: '',
    production: '1:949766001903:web:ad7a1eb5c678b745be771a',
  });

/**
 * The Google Tag Manager id.
 */
export const GOOGLE_TAG_MANAGER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ||
  getenv('GOOGLE_TAG_MANAGER_ID', {
    development: '',
    test: '',
    production: '',
  });

/**
 * The interval for auto saving the cost estimate in ms.
 */
export const COST_ESTIMATE_AUTO_SAVE_INTERVAL = Number(
  process.env.REACT_APP_COST_ESTIMATE_AUTO_SAVE_INTERVAL ||
    getenv('COST_ESTIMATE_AUTO_SAVE_INTERVAL', {
      development: 10000,
      test: 10000,
      production: '',
    })
);
