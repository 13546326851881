import { Box, Collapse, Tabs } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useState } from 'react';
import AssociatedProjectsList from './AssociatedProjectsList';
import HoursSummary from './HoursSummary';
import { useCostEstimatePartData } from '../part/CostEstimatePart';

/**
 * Projects associated with the cost estimate collection.
 */
export default function AssociatedProjects() {
  const [opened, setOpened] = useState(true);
  const { data } = useCostEstimatePartData();

  return (
    <div className="flex flex-col rounded-lg bg-white">
      <div className="px-3">
        <GroupHeadline heading={_t('Associated projects')} opened={opened} setOpened={setOpened} uppercase={false} />
      </div>
      <Collapse in={opened}>
        <Box pb={16}>
          <Tabs defaultValue="projects">
            <Tabs.List px={40} className="border-b-neutral-100" hidden={data.projects.length === 0}>
              <Tabs.Tab value="projects">{_t('Projects')}</Tabs.Tab>
              <Tabs.Tab value="hours">{_t('Hours per position')}</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="projects">
              <AssociatedProjectsList />
            </Tabs.Panel>
            <Tabs.Panel value="hours">
              <HoursSummary />
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Collapse>
    </div>
  );
}
