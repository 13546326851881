import { Progress } from '@mantine/core';
import { useMemo } from 'react';

/**
 * Displays a progress bar colored according to the percentage of the consumed value.
 *
 * @param {{
 *   variant: 'positive' | 'negative' | 'constant',
 *   value: number,
 *   height?: number,
 * }}
 */
export default function ProgressBar({ variant, value, height = 4 }) {
  const barColor = useMemo(() => {
    if (variant === 'positive') {
      if (value < 100) {
        return 'bg-complementary-positive';
      }

      if (value === 100) {
        return 'bg-complementary-action';
      }

      return 'bg-complementary-danger';
    }

    if (variant === 'negative') {
      if (value <= 75) {
        return 'bg-complementary-danger';
      }

      if (value < 100) {
        return 'bg-complementary-action';
      }

      return 'bg-complementary-positive';
    }

    return 'bg-complementary-positive';
  }, [variant, value]);

  const classNames = useMemo(
    () => ({ bar: barColor, root: 'bg-neutral-100  rounded-[4px] w-full transition-all' }),
    [barColor]
  );

  return <Progress h={height} value={Math.min(100, value)} classNames={classNames} />;
}
