/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData').CostEstimateRowData} CostEstimateRowData */

import { Menu } from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  createCostEstimateRowData,
  CUSTOM_JOB_POSITION_ITEM_ID,
} from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import DeleteIcon from 'components/icons/DeleteIcon';
import DuplicateIcon from 'components/icons/DuplicateIcon';
import JobPosition from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/JobPosition';
import Note from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/Note';
import Unit from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/Unit';
import Number from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/Number';
import UnitPrice from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/UnitPrice';
import Price from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/Price';
import ExternalCosts from 'pages/finance/cost-estimate/add-cost-estimate/components/row/inputs/ExternalCosts';
import CommentIcon from 'components/icons/CommentIcon';
import EditIcon from 'components/icons/EditIcon';
import useImmutableWithDebounce from 'hooks/use-immutable-with-debounce';
import CommentModal from 'pages/finance/cost-estimate/add-cost-estimate/components/CommentModal';
import { useDrag, useDrop } from 'react-dnd';
import DragIcon from 'components/icons/DragIcon';
import CommentTooltip from 'components/comments/CommentTooltip';
import { useData } from '../../providers/DataProvider';
import { useApi } from 'api/ApiContext';

/**
 * A single row in a cost estimate section.
 *
 * @param {{
 *   id: string;
 *   sectionId: string;
 *   onChange: (value: CostEstimateRowData) => void;
 *   onDelete: () => void;
 *   onDuplicate: (value: CostEstimateRowData) => void;
 *   swapRows: (id1: string, id2: string) => void;
 *   isInHouse: boolean;
 *   initialData?: Partial<CostEstimateRowData>;
 * }}
 */
export default function CostEstimateRow({
  id,
  sectionId,
  onDuplicate,
  onChange,
  onDelete,
  swapRows,
  isInHouse,
  initialData = {},
}) {
  const { workspaceConfig } = useApi();
  const { data: costEstimateData } = useData();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [commentModalOpened, setCommentModalOpened] = useState(false);

  const hasDifferentCurrency = useMemo(
    () => costEstimateData.currency !== workspaceConfig.currency,
    [costEstimateData.currency, workspaceConfig.currency]
  );

  const augmentedInitialData = useMemo(
    () =>
      isInHouse
        ? initialData
        : {
            ...initialData,
            jobPositionId: CUSTOM_JOB_POSITION_ITEM_ID,
            number: 1,
            unit: 'pcs',
          },
    [isInHouse, initialData]
  );

  const [data, updateData] = useImmutableWithDebounce(augmentedInitialData, {
    createObjectFn: createCostEstimateRowData,
    minWait: 100, // ms
    maxWait: 500, // ms
  });

  // Drag & Drop
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: sectionId,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      const dragId = item.id;
      const hoverId = id;

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      swapRows(dragId, hoverId);
    },
  });

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: sectionId,
    item: () => ({ id }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  }));

  drag(dragRef);
  drop(preview(previewRef));

  /**
   * Prompts the user to add a comment.
   */
  function addComment() {
    setCommentModalOpened(true);
  }

  // Propagate changes.
  useEffect(() => {
    onChange(data);
  }, [data]);

  const gridCols = isInHouse
    ? 'grid-cols-[24px_264px_1fr_80px_80px_110px_140px_128px_24px_24px]'
    : 'grid-cols-[24px_1fr_140px_128px_24px_24px]';

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  return (
    <div
      data-handler-id={handlerId}
      ref={previewRef}
      style={{ opacity }}
      className={`group grid h-[52px] gap-x-2 border-y border-y-[transparent] px-2 py-[7px] text-base text-grey-500 hover:border-y-neutral-50 hover:bg-neutral-20 ${gridCols}`}
    >
      <div ref={dragRef} className="flex cursor-grab items-center justify-center opacity-0 group-hover:opacity-100">
        <DragIcon />
      </div>

      {isInHouse && <JobPosition data={data} updateData={updateData} hasDifferentCurrency={hasDifferentCurrency} />}
      <Note data={data} updateData={updateData} />
      {isInHouse && <Unit data={data} updateData={updateData} />}
      {isInHouse && <Number data={data} updateData={updateData} />}
      <UnitPrice data={data} updateData={updateData} />
      {isInHouse && <Price data={data} />}
      <ExternalCosts data={data} updateData={updateData} />

      <div className="flex items-center justify-center">
        {data.comment && <CommentTooltip comment={data.comment} />}
      </div>

      <div className={`flex items-center justify-center ${menuClasses}`}>
        <Menu width={200} offset={15} onChange={setIsMenuOpened}>
          <Menu.Target>
            <div className="flex cursor-pointer items-center justify-center">
              <OptionsDotsIcon />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={onDelete} icon={<DeleteIcon />}>
              <span>{_t('Delete')}</span>
            </Menu.Item>
            <Menu.Item onClick={() => onDuplicate(data)} icon={<DuplicateIcon />}>
              <span>{_t('Duplicate')}</span>
            </Menu.Item>
            <Menu.Divider />
            {data.comment ? (
              <>
                <Menu.Item onClick={addComment} icon={<EditIcon />}>
                  <span>{_t('Edit Comment')}</span>
                </Menu.Item>
                <Menu.Item onClick={() => updateData({ comment: null })} icon={<DeleteIcon />}>
                  <span>{_t('Remove Comment')}</span>
                </Menu.Item>
              </>
            ) : (
              <Menu.Item onClick={addComment} icon={<CommentIcon />}>
                <span>{_t('Add Comment')}</span>
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </div>

      <CommentModal
        opened={commentModalOpened}
        initialComment={data.comment}
        onClose={(comment) => {
          setCommentModalOpened(false);
          updateData({ comment: comment || null });
        }}
      />
    </div>
  );
}
