/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */

import { Collapse, Menu, Stack } from '@mantine/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { createCostEstimatePartData } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimatePartData';
import GroupHeadline from 'components/GroupHeadline';
import useImmutable from 'hooks/use-immutable';
import CostEstimatePartSections from 'pages/finance/cost-estimate/add-cost-estimate/components/part/CostEstimatePartSections';
import InternalCostOverview from 'pages/finance/cost-estimate/add-cost-estimate/components/part/InternalCostOverview';
import AssociatedProjects from 'pages/finance/cost-estimate/add-cost-estimate/components/associated-project/AssociatedProjects';
import OptionsDotsIcon from '../../../../../../components/icons/OptionsDotsIcon';
import DeleteIcon from '../../../../../../components/icons/DeleteIcon';
import { _t } from '../../../../../../lang';
import DuplicateIcon from '../../../../../../components/icons/DuplicateIcon';
import { createContext } from 'react';

/**
 * @type {React.Context<{
 *   data: CostEstimatePartData;
 *   updateData: (data: CostEstimatePartData|((data: CostEstimatePartData) => Partial<CostEstimatePartData>)) => void;
 * }>}
 */
const CostEstimatePartContext = createContext();

/**
 * Uses the provided data to create the context.
 */
export function useCostEstimatePartData() {
  return useContext(CostEstimatePartContext);
}

/**
 * Project cost price Costs type.
 *
 * @param {{
 *   title: string,
 *   onChange: (data: CostEstimatePartData) => void,
 *   initialData: CostEstimatePartData,
 *   onDelete: () => void,
 *   onDuplicate: () => void,
 * }}
 */
export default function CostEstimatePart({ title, onChange, initialData, onDelete, onDuplicate }) {
  const [opened, setOpened] = useState(true);
  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimatePartData });
  const context = useMemo(() => ({ data, updateData }), [data, updateData]);

  // Propagate data.
  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <CostEstimatePartContext.Provider value={context}>
      <Stack spacing={16}>
        <GroupHeadline
          heading={title}
          opened={opened}
          setOpened={setOpened}
          actions={
            <Menu width={200} offset={15}>
              <Menu.Target>
                <div className="flex cursor-pointer items-center justify-center">
                  <OptionsDotsIcon />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item icon={<DuplicateIcon />} onClick={onDuplicate}>
                  <span>{_t('Duplicate collection')}</span>
                </Menu.Item>
                <Menu.Item icon={<DeleteIcon stroke="#BF0D38" />} onClick={onDelete}>
                  <span className="text-complementary-danger">{_t('Delete collection')}</span>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          }
        />

        <Collapse in={opened}>
          <Stack spacing={16}>
            <AssociatedProjects />
            <CostEstimatePartSections />
            <InternalCostOverview />
          </Stack>
        </Collapse>
      </Stack>
    </CostEstimatePartContext.Provider>
  );
}
