import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { useParams } from 'react-router';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import CostEstimateDetailContent from 'pages/finance/cost-estimate/detail/CostEstimateDetailContent';
import { CostEstimateDetailProvider } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import { useEffect, useState } from 'react';
import NotFoundPage from 'pages/system/not-found/NotFoundPage';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE } from 'utils/constants';

/**
 * Displays the cost estimate detail page.
 */
export default function CostEstimateDetail() {
  const { costEstimateId } = useParams();
  const { getAction } = useApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const costEstimateGetAction = getAction('CostEstimateGetAction');

    setLoading(true);
    setData(null);

    costEstimateGetAction({ parameters: { cost_estimate_id: costEstimateId } })
      .then(setData)
      .catch((e) => {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setData(null);
        } else {
          panic(e);
        }
      })
      .finally(() => setLoading(false));
  }, [getAction, costEstimateId]);

  if (loading) {
    return <DashboardLayout />;
  }

  if (!loading && !data) {
    return <NotFoundPage />;
  }

  return (
    <DashboardLayout key={costEstimateId}>
      <CostEstimateDetailProvider costEstimate={data} key={costEstimateId}>
        <CostEstimateDetailContent />
      </CostEstimateDetailProvider>
    </DashboardLayout>
  );
}
