/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';

/**
 * Input inside a CostEstimateRow to specify a note.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function Note({ data, updateData }) {
  const [note, setNote] = useState(data.note);

  // Propagate changes with debounce.
  useEffect(() => {
    updateData({ note });
  }, [note]);

  return (
    <TextInput
      disabled={!data.isCustomItem}
      value={data.isCustomItem ? note : ''}
      onChange={({ target: { value: note } }) => setNote(note)}
    />
  );
}
