/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { Link } from 'react-router-dom';
import { Button, Collapse, Group, Menu } from '@mantine/core';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import FinanceTableRow from 'pages/finance/finance-project-overview/cost-estimate-table/FinanceTableRow';
import { _t } from 'lang';
import EditIcon from 'components/icons/EditIcon';
import {
  FINANCE_ADD_EXTERNAL_COST_PAGE_PATH,
  FINANCE_ADD_INVOICE_PAGE_PATH,
  FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH,
  FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH,
  FINANCE_EDIT_EXTERNAL_COST_PAGE_PATH,
  FINANCE_EDIT_INVOICE_PAGE_PATH,
  FINANCE_EDIT_INVOICE_REQUEST_PAGE_PATH,
} from 'routes/paths';
import AddIcon from 'components/icons/AddIcon';
import CollapseArrow from 'components/CollapseArrow';
import { useMemo, useState } from 'react';
import CostEstimateIcon from 'components/icons/CostEstimateIcon';
import InvoiceIcon from 'components/icons/InvoiceIcon';
import ExternalInvoiceIcon from 'components/icons/ExternalInvoiceIcon';
import PurchaseOrderIcon from 'components/icons/PurchaseOrderIcon';
import FinancialValueCell from 'pages/finance/finance-project-overview/cost-estimate-table/FinancialValueCell';
import CostEstimateStatusBubble from 'components/CostEstimateStatusBubble';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { isEmpty } from 'lodash';
import Truncate from 'components/Truncate';

/**
 * The cost estimate contents of the Finance overview page.
 *
 * @param {{costEstimate: CostEstimate}}
 */
export default function CostEstimateTable({ costEstimate }) {
  const costEstimateId = costEstimate.cost_estimate_id;

  const localStorageKey = useMemo(
    () => `toolio.finance.overview.costEstimate.${costEstimateId}.opened`,
    [costEstimateId]
  );

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [opened, { toggle: toggleOpened }] = useLocalStorageDisclosure(localStorageKey, false);

  const addInvoiceRequestPagePath = FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH.insert({ costEstimateId });
  const addInvoicePagePath = FINANCE_ADD_INVOICE_PAGE_PATH.insert({ costEstimateId });
  const addExternalCostPagePath = FINANCE_ADD_EXTERNAL_COST_PAGE_PATH.insert({ costEstimateId });

  const editCostEstimatePagePath = FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });
  const detailCostEstimatePagePath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });

  const costEstimatePagePath = costEstimate.is_draft ? editCostEstimatePagePath : detailCostEstimatePagePath;

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  const gridLayout = !costEstimate.is_draft
    ? 'grid-cols-[24px_1fr_128px_128px_128px_128px_24px]'
    : 'grid-cols-[24px_1fr_128px_24px]';

  return (
    <div className="border-t border-t-neutral-100">
      <div className={`group grid h-12 items-center gap-2 px-2 hover:bg-neutral-20 ${gridLayout}`}>
        <div></div> {/* drag and drop icon */}
        <div className="flex flex-row items-center gap-2 py-3">
          <div className="h-6 w-6 cursor-pointer" onClick={toggleOpened}>
            {!costEstimate.is_draft && <CollapseArrow opened={opened} />}
          </div>

          <Group maw={590}>
            <Link
              to={costEstimatePagePath}
              className="group/cost-estimate-name fill-neutral-700 text-[15px] leading-[18px] transition-all hover:text-hyperlink"
            >
              <Group noWrap spacing={4} maw={590}>
                <CostEstimateIcon
                  width={24}
                  height={24}
                  fill=""
                  className="group-hover/cost-estimate-name:!fill-hyperlink"
                />
                <Truncate
                  className="text-[15px] leading-[18px]"
                  text={`${costEstimate.cost_estimate_name} ${costEstimate.is_draft ? _t('(draft)') : ''}`}
                />
              </Group>
            </Link>
          </Group>
        </div>
        {!costEstimate.is_draft && (
          <>
            <div className="py-3">
              {!costEstimate.declined_at && (
                <FinancialValueCell value={costEstimate.invoiced_sum} currency={costEstimate.currency} />
              )}
            </div>
            <div className="py-3">
              {!costEstimate.declined_at && (
                <FinancialValueCell value={costEstimate.budget} currency={costEstimate.currency} />
              )}
            </div>
            <div className="py-3">
              <FinancialValueCell value={costEstimate.external_cost_sum_actual} currency={costEstimate.currency} />
            </div>
          </>
        )}
        <div className="py-3">
          <CostEstimateStatusBubble costEstimate={costEstimate} variant="small" />
        </div>
        <div className={`flex justify-end py-3 ${menuClasses}`}>
          <Menu position="bottom-end" offset={15} onChange={setIsMenuOpened}>
            <Menu.Target>
              <div className="-my-2 cursor-pointer py-2">
                <OptionsDotsIcon />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {!costEstimate.approved_at && (
                <Menu.Item component={Link} to={editCostEstimatePagePath} icon={<EditIcon fill="#4D4D4D" />}>
                  {_t('Edit')}
                </Menu.Item>
              )}
              {!costEstimate.is_draft && !costEstimate.purchase_order && !costEstimate.closed_at && (
                <Menu.Item component={Link} to={addInvoiceRequestPagePath} icon={<AddIcon stroke="#4D4D4D" />}>
                  {_t('Add PO')}
                </Menu.Item>
              )}
              {!costEstimate.is_draft && !costEstimate.closed_at && (
                <Menu.Item component={Link} to={addExternalCostPagePath} icon={<AddIcon stroke="#4D4D4D" />}>
                  {_t('Add external costs')}
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
      {!costEstimate.is_draft && (
        <Collapse in={opened} className={`${opened ? 'border-t border-t-neutral-100' : ''}`}>
          {/* PURCHASE ORDER ROW */}
          {costEstimate.purchase_order && (
            <FinanceTableRow
              icon={<PurchaseOrderIcon fill="" />}
              name={costEstimate.purchase_order.purchase_order_name}
              invoice={costEstimate.amount_after_discount}
              currency={costEstimate.currency}
              pagePath={`${costEstimatePagePath}?tab=purchase-order`}
              editPath={FINANCE_EDIT_INVOICE_REQUEST_PAGE_PATH.insert({
                costEstimateId,
                purchaseOrderId: costEstimate.purchase_order.purchase_order_id,
              })}
            />
          )}

          {/* INVOICE ROW */}
          {costEstimate.invoice && (
            <FinanceTableRow
              icon={<InvoiceIcon fill="" />}
              name={costEstimate.invoice.invoice_name}
              currency={costEstimate.currency}
              invoice={costEstimate.amount_after_discount}
              pagePath={`${costEstimatePagePath}?tab=invoice`}
              editPath={FINANCE_EDIT_INVOICE_PAGE_PATH.insert({
                costEstimateId,
                invoiceId: costEstimate.invoice.invoice_id,
              })}
            />
          )}

          {/* EXTERNAL COSTS ROWS */}
          {(costEstimate.external_costs || []).map((externalCost) => (
            <FinanceTableRow
              key={externalCost.external_cost_id}
              icon={<ExternalInvoiceIcon fill="" />}
              name={externalCost.external_cost_name}
              externalCost={externalCost.amount}
              currency={externalCost.currency}
              pagePath={`${costEstimatePagePath}?tab=external-cost&externalCostId=${externalCost.external_cost_id}`}
              editPath={FINANCE_EDIT_EXTERNAL_COST_PAGE_PATH.insert({
                costEstimateId,
                externalCostId: externalCost.external_cost_id,
              })}
            />
          ))}

          {/* Create **something** for the cost estimate buttons */}
          {!costEstimate.closed_at && (
            <div className="flex h-12 flex-row gap-8 pb-3 pl-[105px] pt-3">
              {(!costEstimate.invoice_request || !costEstimate.purchase_order) && !costEstimate.closed_at && (
                <Button
                  leftIcon={<AddIcon stroke="#38298B" />}
                  component={Link}
                  to={addInvoiceRequestPagePath}
                  variant="link"
                >
                  {_t('Create an invoice request and purchase order')}
                </Button>
              )}

              {!costEstimate.invoice && !costEstimate.closed_at && (
                <Button leftIcon={<AddIcon stroke="#38298B" />} component={Link} to={addInvoicePagePath} variant="link">
                  {_t('Upload an invoice')}
                </Button>
              )}

              {!costEstimate.closed_at && (
                <Button
                  leftIcon={<AddIcon stroke="#38298B" />}
                  component={Link}
                  to={addExternalCostPagePath}
                  variant="link"
                >
                  {_t('Add external cost')}
                </Button>
              )}
            </div>
          )}

          {costEstimate.closed_at &&
            !costEstimate.purchase_order &&
            !costEstimate.invoice &&
            isEmpty(costEstimate.external_costs) && (
              <div className="flex h-12 flex-row items-center gap-8 pl-[95px] text-[15px] leading-[18px]">
                {_t('Cost estimate is closed and has no uploaded documents.')}
              </div>
            )}
        </Collapse>
      )}
    </div>
  );
}
