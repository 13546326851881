import { Box, Button, Center, Group, Stack } from '@mantine/core';
import { useData } from '../../providers/DataProvider';
import { useDisclosure } from '@mantine/hooks';
import PriceDisplay from 'components/PriceDisplay';
import { _t } from 'lang';
import DiscountForm from '../../components/discount/DiscountForm';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import { useEffect } from 'react';

/**
 * Discount section in the cost estimate form.
 */
export default function DiscountSection() {
  const { data, updateData } = useData();
  const [hasDiscount, { open: addDiscount, close: removeDiscount }] = useDisclosure(!!data.discount);

  // Remove discount from data if it is disabled.
  useEffect(() => {
    if (!hasDiscount) {
      updateData({ discount: undefined });
    }
  }, [hasDiscount]);

  return (
    <Box py={24} className={hasDiscount ? 'group' : 'group'}>
      {hasDiscount && (
        <Stack pb={24} px={8} mb={16} spacing={16} className="border-b border-b-neutral-300">
          <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
            <span className="text-[15px] leading-[18px]">{_t('Subtotal:')}</span>
            <PriceDisplay value={data.subtotal} currency={data.currency} />
          </div>
          <DiscountForm
            onDelete={removeDiscount}
            onChange={(discount) => updateData({ discount })}
            discountAmount={data.discountAmount}
            initialData={data.discount || undefined}
            currency={data.currency || 'Lorem'}
          />
        </Stack>
      )}
      <Group spacing={8} position="apart">
        <Center px={8}>
          {!hasDiscount && (
            <Button
              variant="link"
              leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
              onClick={addDiscount}
            >
              {_t('Add discount')}
            </Button>
          )}
        </Center>
        <Group px={8} spacing={8} align="baseline" className="text-right">
          <Box w={91}>{_t('Total:')}</Box>
          <PriceDisplay value={data.total} size="xl" currency={data.currency} />
          <PriceDisplay
            value={data.externalCosts}
            className="w-[128px] text-complementary-african-violet"
            currency={data.currency}
          />
          <Box w={64} /> {/* Empty */}
        </Group>
      </Group>
    </Box>
  );
}
