import FormRow from 'components/forms/FormRow';
import ClientSelect from 'components/selects/ClientSelect';
import { _t } from 'lang';
import { useClient } from 'providers/client/ClientProvider';

/**
 * The client of a cost estimate.
 */
export default function CostEstimateClient() {
  const { clientId, setClientId } = useClient();

  return (
    <FormRow
      label={_t('Client')}
      input={<ClientSelect value={String(clientId)} onChange={(clientId) => setClientId(Number(clientId))} disabled />}
    />
  );
}
