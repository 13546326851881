import { Stack } from '@mantine/core';
import { useData } from '../../providers/DataProvider';
import Alert from 'components/Alert';
import { _t } from 'lang';
import formatPrice from 'utils/format-price';
import { useApi } from 'api/ApiContext';
import { useMemo } from 'react';

/**
 * Warning section for cost estimate.
 */
export default function WarningsSection() {
  const { workspaceConfig } = useApi();
  const { data } = useData();

  const hasZeroPrice = useMemo(
    () => data.collections.some(({ sections }) => sections.some(({ rows }) => rows.some(({ price }) => price === 0))),
    [data.collections]
  );

  return (
    <Stack spacing={0}>
      {data.externalCosts > data.total && (
        <Stack spacing={0} py={8}>
          <Alert
            severity="error"
            primaryText={_t(
              'This cost estimate is not profitable. Planned external cost of %s exceeds budgeted costs of %s by %s.',
              formatPrice(data.externalCosts, workspaceConfig.currency),
              formatPrice(data.total, workspaceConfig.currency),
              formatPrice(data.externalCosts - data.total, workspaceConfig.currency)
            )}
          />
        </Stack>
      )}

      {hasZeroPrice && (
        <Stack spacing={0} py={8}>
          <Alert
            severity="actionRequired"
            primaryText={_t('Reminder: Null items in cost estimate, please double-check if intended.')}
          />
        </Stack>
      )}
    </Stack>
  );
}
