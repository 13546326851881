/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */

import { useMemo } from 'react';
import { isNumber } from 'lodash';
import { Group } from '@mantine/core';
import PriceDisplay from 'components/PriceDisplay';
import ProgressGroup from 'components/progress/ProgressGroup';

/**
 * A summary of hours per position.
 *
 * @param {{
 *   data: CostEstimatePartData,
 *   name: string,
 *   jobPositionId: number,
 *   spentHours: number,
 * }}
 */
export default function HoursSummaryJobPosition({ data, name, jobPositionId, spentHours }) {
  // TODO: Use some kind of index to speed up this calculation.
  const allocatedHours = useMemo(() => {
    let hours = 0;

    for (const section of data.sections) {
      for (const row of section.rows) {
        if (row.jobPositionId === jobPositionId && isNumber(row.number)) {
          hours += row.number;
        }
      }
    }

    return hours;
  }, [data.sections]);

  return (
    <Group h={48} align="center" className="grid grid-cols-[1fr_112px_112px_112px] items-center gap-4" pr={32}>
      <span>{name}</span>
      <PriceDisplay className="text-right" value={allocatedHours} currency="hrs." />
      <PriceDisplay className="text-right" value={spentHours} currency="hrs." />
      <ProgressGroup part={spentHours} total={allocatedHours} variant="positive" />
    </Group>
  );
}
