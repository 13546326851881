/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { usePriceList } from 'providers/price-list/PriceListProvider';
import MoneyInput from 'components/inputs/MoneyInput';
import { useData } from '../../../providers/DataProvider';

/**
 * Input inside a CostEstimateRow to specify a unit price.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function UnitPrice({ data, updateData }) {
  const [unitPrice, setUnitPrice] = useState(data.unitPrice);
  const [unitCost, setUnitCost] = useState(data.unitCost);
  const { priceList } = usePriceList();
  const { data: costEstimateData } = useData();

  const suffix = data.unit === 'pcs' ? '' : '/h';
  const currencyPretty = `${costEstimateData.currency}${suffix}`;

  // Set the unit price based on price list.
  useEffect(() => {
    if (priceList && !data.isCustomItem) {
      const priceListItem = priceList
        ? find(priceList.price_list_items, { position_in_company_id: data.jobPositionId })
        : null;

      const unitPrice = priceListItem ? priceListItem.project_based_rate : undefined;
      const unitCost = priceListItem ? priceListItem.internal_cost : undefined;

      setUnitPrice(unitPrice);
      setUnitCost(unitCost);
    }
  }, [data.jobPositionId, priceList]);

  // Propagate changes with debounce.
  useEffect(() => {
    updateData({ unitPrice, unitCost });
  }, [unitPrice, unitCost]);

  return (
    <MoneyInput
      disabled={!data.isCustomItem}
      currency={currencyPretty}
      min={0}
      precision={2}
      step={1}
      value={unitPrice}
      onChange={setUnitPrice}
    />
  );
}
