/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */

import { Box, Button, Stack } from '@mantine/core';
import CostEstimatePart from '../../components/part/CostEstimatePart';
import { useData } from '../../providers/DataProvider';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { _t } from 'lang';

/**
 * Collections of the cost estimate.
 */
export default function CollectionsSection() {
  const { data, updateData } = useData();
  const [collections, setCollections] = useState(() => data.collections.map(createCollection));
  const [, setNextIndex] = useState(collections.length);

  /**
   * Handles collection change.
   *
   * @param {string} id
   * @param {CostEstimatePartData} data
   */
  function onCollectionChange(id, data) {
    setCollections((collections) =>
      collections.map((collection) => (collection.id === id ? { ...collection, data } : collection))
    );
  }

  /**
   * Deletes a collection.
   *
   * @param {string} id
   */
  function deleteCollection(id) {
    setCollections((collections) => collections.filter((collection) => collection.id !== id));
  }

  /**
   * Creates an empty collection.
   *
   * @param {CostEstimatePartData} [data]
   * @param {number} [index]
   */
  function createCollection(data, index) {
    const id = nanoid();

    const component = (
      <CostEstimatePart
        key={id}
        title={_t('COST ESTIMATE (COLLECTION %s)', index + 1)}
        initialData={data}
        onChange={(data) => onCollectionChange(id, data)}
        onDelete={() => deleteCollection(id)}
        onDuplicate={() => {
          //TODO: Implement onDuplicate
        }}
      />
    );

    return { id, component };
  }

  /**
   * Adds a collection.
   *
   * @param {CostEstimatePartData} [data]
   */
  function addCollection(data) {
    setNextIndex((index) => {
      const newCollection = createCollection(data, index);
      setCollections((currCollections) => [...currCollections, newCollection]);
      return index + 1;
    });
  }

  // Compute data.
  useEffect(() => {
    updateData({
      collections: collections.map(({ data }) => data).filter(Boolean),
    });
  }, [collections]);

  return (
    <Stack spacing={16}>
      {collections.map(({ component }) => component)}

      <Box py={16}>
        <Button
          variant="secondary"
          leftIcon={<PlusSmallIcon />}
          onClick={() =>
            addCollection({
              sections: [{ isInHouse: true, rows: [{ isInHouse: true }, { isInHouse: true }, { isInHouse: true }] }],
              projects: [],
            })
          }
        >
          Add collection
        </Button>
      </Box>
    </Stack>
  );
}
