/**
 * Creates a new instance of AssociatedProjectData.
 *
 * @param {{
 *   projectId: number,
 *   name: string,
 *   allTasks: boolean,
 *   tasks: number[],
 * }}
 */
export function createAssociatedProjectData({ projectId, name, allTasks = true, tasks = [] } = {}) {
  return Object.freeze({
    projectId,
    name,
    allTasks,
    tasks,
  });
}

/** @typedef {ReturnType<typeof createAssociatedProjectData>} AssociatedProjectData */
