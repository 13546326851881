/** @typedef {import('api/actions/task-get-list-action/task-get-list-action-response').TaskGetListActionResponse[number]} Task */

import { _t } from 'lang';
import { useMemo } from 'react';

/**
 * Map from color name to Tailwind CSS class.
 */
const bgColors = {
  new: 'bg-status-green',
  'in progress': 'bg-status-yellow',
  closed: 'bg-status-green',
  deleted: 'bg-status-red',
};

/**
 * The status bubble.
 *
 * @param {{
 *   task: Task;
 *   variant?: 'small' | 'default';
 * }}
 */
export default function TaskStatusBubble({ task, variant = 'default' }) {
  const status = useMemo(() => {
    if (task.deleted_at) {
      return 'deleted';
    }

    if (task.closed_at) {
      return 'closed';
    }

    return 'new';
  }, [task]);

  const bgColor = bgColors[status] || 'bg-status-green';
  const styles = variant === 'small' ? 'py-1 text-[12px] leading-[16px]' : 'py-[3px] text-[15px] leading-[18px]';
  const bubbleHeight = variant === 'small' ? 'h-2' : 'h-3';
  const bubbleWidth = variant === 'small' ? 'w-2' : 'w-3';

  let statusText = '';
  if (status === 'new') {
    statusText = _t('Open');
  } else if (status === 'closed') {
    statusText = _t('Closed');
  } else if (status === 'deleted') {
    statusText = _t('Deleted');
  }

  return (
    <div className={`flex h-6 flex-row items-center gap-1 text-neutral-700 ${styles}`}>
      <div className={`rounded-full ${bubbleHeight} ${bubbleWidth} ${bgColor}`}></div>
      <span>{statusText}</span>
    </div>
  );
}
