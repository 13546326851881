/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */

import { useEffect, useState } from 'react';
import GroupHeadline from '../../../../../../components/GroupHeadline';
import { _t } from '../../../../../../lang';
import { Button, Collapse, Group } from '@mantine/core';
import PlusSmallIcon from '../../../../../../components/icons/PlusSmallIcon';
import CostEstimateSection from '../section/CostEstimateSection';
import { nanoid } from 'nanoid';
import { useCostEstimatePartData } from './CostEstimatePart';

/**
 * Cost estimate component.
 */
export default function CostEstimatePartSections() {
  const { data, updateData } = useCostEstimatePartData();
  const [opened, setOpened] = useState(true);
  const [sections, setSections] = useState(() => data.sections.map(createSection));

  /**
   * Handles section change.
   *
   * @param {string} id
   * @param {CostEstimateSectionData} data
   */
  function onSectionChange(id, data) {
    setSections((sections) => sections.map((section) => (section.id === id ? { ...section, data } : section)));
  }

  /**
   * Deletes a section.
   *
   * @param {string} id
   */
  function deleteSection(id) {
    setSections((sections) => sections.filter((section) => section.id !== id));
  }

  /**
   * Creates an empty section.
   *
   * @param {CostEstimateSectionData} [data]
   * @param {{
   *   autoFocus?: boolean
   * }}
   */
  function createSection(data, { autoFocus = false } = {}) {
    const id = nanoid();

    const component = (
      <CostEstimateSection
        key={id}
        id={id}
        initialData={data}
        isInHouse={data?.isInHouse}
        onChange={(data) => onSectionChange(id, data)}
        onDelete={() => deleteSection(id)}
        onDuplicate={(data) => addSection(data)} // The declaration is hoisted, so this is fine.
        autoFocus={autoFocus}
        swapSections={swapSections}
      />
    );

    return { id, component };
  }

  /**
   * Adds a section.
   *
   * @param {CostEstimateSectionData} [data]
   */
  function addSection(data) {
    const newSection = createSection(data, { autoFocus: true });
    setSections((currSections) => [...currSections, newSection]);
  }

  /**
   * Adds an empty section.
   *
   * @param {{
   *   isInHouse: boolean
   * }}
   */
  function addEmptySection({ isInHouse }) {
    addSection({ isInHouse, rows: [{ isInHouse }, { isInHouse }, { isInHouse }] });
  }

  /**
   * Swap two sections.
   *
   * @param id1
   * @param id2
   */
  function swapSections(id1, id2) {
    setSections((sections) => {
      const index1 = sections.findIndex((section) => section.id === id1);
      const index2 = sections.findIndex((section) => section.id === id2);

      const newSections = [...sections];
      [newSections[index1], newSections[index2]] = [newSections[index2], newSections[index1]];

      return newSections;
    });
  }

  // Compute data.
  useEffect(() => {
    updateData({
      sections: sections.map(({ data }) => data).filter(Boolean),
    });
  }, [sections]);

  return (
    <div className="flex flex-col rounded-lg bg-white">
      <div className="px-2">
        <GroupHeadline heading={_t('Cost estimate')} opened={opened} setOpened={setOpened} uppercase={false} />
      </div>
      <Collapse in={opened}>
        {sections.map(({ component }) => component)}

        <Group py={16} px={40}>
          <Button
            variant="secondary"
            leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
            onClick={() => addEmptySection({ isInHouse: true })}
          >
            {_t('Add in house section')}
          </Button>
          <Button
            variant="secondary"
            leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
            onClick={() => addEmptySection({ isInHouse: false })}
          >
            {_t('Add out of house section')}
          </Button>
        </Group>
      </Collapse>
    </div>
  );
}
