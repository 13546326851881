/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-collection-assign-action-body').CostEstimateCollectionAssignActionBody} CostEstimateCollectionAssignActionBody
 */

/**
 * @typedef {import('./cost-estimate-collection-assign-action-response').CostEstimateCollectionAssignActionResponse} CostEstimateCollectionAssignActionResponse
 */

/**
 * The path of the CostEstimateCollectionAssignAction action.
 */
export const costEstimateCollectionAssignActionPath = new SweetPath(
  '/api/finance/cost_estimate_collection/{cost_estimate_collection_id}/assign',
  { path: '{param}' }
);

/**
 * Creates a contextualized CostEstimateCollectionAssignAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateCollectionAssignAction(axios) {
  /**
   * The costEstimateCollectionAssignAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateCollectionAssignActionBody } }
   *
   * @returns {Promise<CostEstimateCollectionAssignActionResponse>}
   */
  function costEstimateCollectionAssignAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateCollectionAssignActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return costEstimateCollectionAssignAction;
}
