import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useClient } from 'providers/client/ClientProvider';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH, FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { CUSTOM_JOB_POSITION_ITEM_ID } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { _t } from 'lang';

/**
 * The submit context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   save: ({ isDraft, withoutNotification }: { isDraft: boolean; withoutNotification: boolean }) => Promise<void>,
 *   isSaving: boolean,
 * }>}
 */
export const SubmitContext = createContext();

/**
 * Provides the submit context to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function SubmitProvider({ children }) {
  const navigate = useNavigate();

  const { getAction, fullName } = useApi();
  const costEstimateCreateAction = getAction('CostEstimateCreateAction');
  const costEstimateUpdateAction = getAction('CostEstimateUpdateAction');

  const { clientId } = useClient();
  const { data, updateData } = useData();

  const [isSaving, setIsSaving] = useState(false);

  /**
   * Parses the discount type.
   *
   * @param {'relative' | 'absolute'} discountType
   */
  const parseDiscountType = useCallback(
    (discountType) => (discountType === 'relative' ? '%' : data.currency),
    [data.currency]
  );

  /**
   * Saves the cost estimate as a draft.
   *
   * @param {{
   *   isDraft: boolean,
   *   withoutNotification?: boolean,
   * }}
   */
  function save({ isDraft, withoutNotification = false }) {
    if (isSaving) {
      return; // Do not save twice.
    }

    setIsSaving(true);

    const action = data.costEstimateId ? costEstimateUpdateAction : costEstimateCreateAction;

    action({
      body: {
        is_draft: isDraft,
        client_id: Number(clientId),
        cost_estimate_name: data.name || 'Untitled cost estimate',
        description: data.description || '',
        note: data.note || '',
        currency: data.currency,
        language: 'sk',
        cost_estimate_price_id: 1000001, // todo: remove this
        discount: data.discount ? data.discount.amount : undefined,
        discount_unit: data.discount ? parseDiscountType(data.discount.type) : undefined,
        discount_comment: data.discount ? data.discount.comment : undefined,
        collections: data.collections.map(({ sections, projects }) => ({
          projects: projects.map(({ projectId, allTasks, tasks }) => ({
            project_id: projectId,
            all_tasks: allTasks,
            tasks,
          })),
          sections: sections.map(({ name, discount, isInHouse, rows, agencyFee }, sectionOrd) => ({
            section_name: name || 'Untitled section',
            is_in_house: isInHouse,
            discount: discount ? discount.amount : undefined,
            discount_unit: discount ? parseDiscountType(discount.type) : undefined,
            discount_comment: discount ? discount.comment : undefined,
            agency_fee: agencyFee ? agencyFee.amount : undefined,
            agency_fee_comment: agencyFee ? agencyFee.comment : undefined,
            ord: sectionOrd,
            items: rows
              .filter(({ jobPositionId }) => jobPositionId)
              .map(
                (
                  { note, number, unit, unitPrice, price, externalCosts, comment, jobPositionId, jobPositionName },
                  itemOrd
                ) => ({
                  title: note || jobPositionName,
                  number: number || 0,
                  unit,
                  unit_price: unitPrice || 0,
                  price: price || 0,
                  external_cost: externalCosts || 0,
                  comment: comment || undefined,
                  position_in_company_id: jobPositionId === CUSTOM_JOB_POSITION_ITEM_ID ? undefined : jobPositionId,
                  ord: itemOrd,
                })
              ),
          })),
        })),
      },
      parameters: { cost_estimate_id: data.costEstimateId },
    })
      .then(({ cost_estimate_id: costEstimateId, comment_thread_id: commentThreadId }) => {
        if (isDraft) {
          updateData({
            costEstimateId,
            isDraft,
            commentThreadId,
            updatedAt: new Date(),
            updatedBy: fullName,
          });

          if (!withoutNotification) {
            showNotification({
              title: _t('Cost estimate saved'),
              message: _t('Cost estimate has been saved as a draft.'),
              color: SUCCESS_NOTIFICATION_COLOR,
            });
          }

          if (!data.costEstimateId) {
            navigate(FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId }), { replace: true });
          }
        } else {
          if (!withoutNotification) {
            showNotification({
              title: _t('Cost estimate sent to confirm'),
              message: _t('Cost estimate has been saved and is now waiting for confirmation.'),
              color: SUCCESS_NOTIFICATION_COLOR,
            });
          }

          navigate(FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId }), { replace: true });
        }
      })
      .catch((error) => {
        // todo: handle specific errors.

        panic(error.message);
      })
      .finally(() => setIsSaving(false));
  }

  const value = useMemo(() => ({ save, isSaving }), [save, isSaving]);

  return <SubmitContext.Provider value={value}>{children}</SubmitContext.Provider>;
}

/**
 * Uses the submit context.
 */
export function useSubmit() {
  return useContext(SubmitContext);
}
