/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse} CostEstimate */

import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import NotFoundPage from 'pages/system/not-found/NotFoundPage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import striptags from 'striptags';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE } from 'utils/constants';

// cspell:disable

/** @type {CostEstimate} */
const emptyCostEstimate = undefined;

/**
 * Displays price with currency.
 *
 * @param {{
 *   value: number,
 *   currency?: string,
 *   largeIntegralPart?: boolean,
 *   className?: string,
 * }}
 */
function PriceDisplay({ value, currency = 'EUR', largeIntegralPart = false, className = '' }) {
  const formatter = new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [integral, decimal] = formatter.format(value).split(',');

  const normalText = 'text-[3.75mm] leading-[4.5mm]';
  const largeText = 'text-[5.5mm] leading-[6mm]';

  const integralText = largeIntegralPart ? largeText : normalText;
  const decimalText = normalText;

  return (
    <span className={className}>
      <span className={integralText}>{integral}</span>
      <span className={decimalText}>,{decimal}</span>
      <span className="ml-[1mm] text-[3mm] leading-[4mm]">{currency}</span>
    </span>
  );
}

/**
 * Page for previewing the PDF of a cost estimate.
 */
export default function CostEstimatePDFPreview() {
  const { costEstimateId } = useParams();
  const { getAction } = useApi();
  const [costEstimate, setCostEstimate] = useState(emptyCostEstimate);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const costEstimateGetAction = getAction('CostEstimateGetAction');

    setLoading(true);
    costEstimateGetAction({
      parameters: {
        cost_estimate_id: costEstimateId,
      },
    })
      .then(setCostEstimate)
      .catch((e) => {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setCostEstimate(null);
        } else {
          panic(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, costEstimateId]);

  // Print the document when the cost estimate is loaded.
  useEffect(() => {
    if (costEstimate) {
      window.onafterprint = window.close;
      document.title = `Cost Estimate - ${costEstimate.cost_estimate_name}`;
      window.print();
    }
  }, [costEstimate]);

  if (loading) {
    return <></>;
  }

  if (!costEstimate) {
    return <NotFoundPage />;
  }

  return (
    <div className="mx-auto h-[297mm] w-[210mm]">
      <header className="flex justify-between gap-[12mm] bg-grey-50 px-[12mm] pb-[10mm] pt-[12mm]">
        <div className="flex flex-col gap-[8mm]">
          <div className="flex flex-col gap-[2mm]">
            <div className="text-[10mm] leading-[7mm]">COST ESTIMATE</div>
            <small className="py-[2mm] text-[3mm] leading-[3.5mm]">{costEstimate?.client?.client_name}</small>
          </div>
          <div className="flex flex-col">
            <div className="w-full text-[6mm] leading-[9mm]">{costEstimate?.cost_estimate_name}</div>
            <p className="h-auto w-full py-[2mm] text-[3mm] leading-[4.5mm]">
              {striptags(costEstimate?.description, [], ' ').replace(/\s+/g, ' ').trim()}
            </p>
          </div>
        </div>

        <div className="flex shrink-0 flex-col items-end gap-[4mm]">
          <svg
            className="h-[20.5mm] w-[20.5mm]"
            width="82"
            height="82"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_7366_52783)">
              <path d="M64 0H0V64H64V0Z" fill="black" />
              <path d="M52.2105 9.54395H13.4736V52.772H52.2105V9.54395Z" fill="black" />
              <path
                d="M16.4165 16.2991C16.4165 12.8944 18.9229 10.6668 21.9992 10.6668C25.0755 10.6668 27.5818 12.8944 27.5818 16.2991C27.5818 19.7038 25.0922 21.9314 21.9992 21.9314C18.9062 21.9314 16.4165 19.7005 16.4165 16.2991ZM21.9992 19.964C24.0389 19.964 25.4621 18.48 25.4621 16.2991C25.4621 14.1182 24.0389 12.6343 21.9992 12.6343C19.9594 12.6343 18.5363 14.1182 18.5363 16.2991C18.5363 18.48 19.9728 19.964 21.9992 19.964Z"
                fill="white"
              />
              <path
                d="M31.9982 10.8835H34.0379V15.0619H39.0006V10.8835H41.0404V21.7113H39.0006V16.9627H34.0379V21.7113H31.9982V10.8835Z"
                fill="white"
              />
              <path
                d="M17.1231 23.9955H19.0863V34.8232H17.1231V23.9955ZM20.726 32.9692L19.0863 23.9955H20.956L22.5958 32.9692H20.726ZM24.329 23.9955H26.1987L24.4523 32.9692H22.5958L24.329 23.9955ZM26.1987 23.9955H28.2385V34.8232H26.1987V23.9955Z"
                fill="white"
              />
              <path
                d="M34.2475 30.1648L31.9577 23.9922H34.2475L36.5372 30.1648L38.9502 23.9922H41.1L38.7036 30.1648H37.5604V34.82H35.5206V30.1648H34.2475Z"
                fill="white"
              />
              <path
                d="M13.9936 51.7705C13.9936 51.7071 14.0135 51.5771 14.0135 51.5771L16.8499 38.5218C16.0067 38.7151 15.1201 38.9986 14.1002 39.3888C14.0569 39.4121 13.9702 39.4321 13.9069 39.4321C13.6469 39.4321 13.4736 39.2154 13.4736 38.9986C13.4736 38.8686 13.537 38.6751 13.7769 38.5885C14.8368 38.1783 16.4833 37.6781 17.0665 37.5714L17.2598 36.641C17.3031 36.4242 17.4765 36.2742 17.6931 36.2742C17.9531 36.2742 18.1264 36.4909 18.1264 36.7077C18.1264 36.7944 18.1064 36.9011 18.1064 36.9011L17.9764 37.4213C18.473 37.358 18.9296 37.3146 19.3396 37.3146C22.0026 37.3146 23.4958 38.7452 23.4958 41.4296C23.4958 45.9982 19.1463 51.237 14.4735 52.2107H14.4302C14.1935 52.2107 13.9969 52.0173 13.9969 51.7772L13.9936 51.7705ZM14.9901 51.187C19.0163 49.9531 22.6292 45.4079 22.6292 41.423C22.6292 39.2354 21.546 38.175 19.3396 38.175C18.863 38.175 18.343 38.2183 17.7831 38.3283L14.9868 51.187H14.9901Z"
                fill="white"
              />
              <path
                d="M25.668 45.6715C25.8346 41.3363 29.9641 37.4514 34.3336 37.4514C37.7498 37.4514 39.9363 39.8057 39.7663 43.0771C39.5996 47.4689 35.6201 51.2804 31.1006 51.2804C27.6844 51.2804 25.498 48.9261 25.668 45.6748V45.6715ZM36.7033 43.3372C36.8533 41.433 35.7501 40.1225 33.8836 40.1225C31.2306 40.1225 28.8976 42.6836 28.7309 45.3714C28.5809 47.2755 29.7008 48.586 31.5506 48.586C34.1636 48.586 36.5366 46.065 36.7033 43.3339V43.3372Z"
                fill="white"
              />
              <path
                d="M45.1438 38.0014H40.4876V37.2611H50.7331V38.0014H46.057V50.9034H45.1404V38.0014H45.1438Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_7366_52783">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div className="inline-block py-[2mm] text-right text-[3mm] leading-[4mm]">
            Vajnorská 100/B
            <br />
            831 04 Bratislava
            <br />
            Slovakia
          </div>
        </div>
      </header>

      <div className="flex flex-col gap-[4mm] px-[8mm]">
        {costEstimate?.collections
          .flatMap(({ sections }) => sections)
          .map((section) => (
            <div key={section.cost_estimate_section_id} style={{ pageBreakInside: 'avoid' }} className="px-[4mm]">
              <div className="py-[4mm] text-[5mm] leading-[9mm]">{section?.section_name}</div>

              <table className="w-full ">
                <thead>
                  {section?.is_in_house ? (
                    <tr>
                      <th className="text-left text-[3mm] font-normal leading-[4.5mm] text-grey-500">Description</th>
                      <th className="text-right text-[3mm] font-normal leading-[4.5mm] text-grey-500">Amount</th>
                      <th className="text-right text-[3mm] font-normal leading-[4.5mm] text-grey-500">Unit Price</th>
                      <th className="text-right text-[3mm] font-normal leading-[4.5mm] text-grey-500">Price</th>
                    </tr>
                  ) : (
                    <tr>
                      <th className="text-left text-[3mm] font-normal leading-[4.5mm] text-grey-500">Description</th>
                      <th className="text-right text-[3mm] font-normal leading-[4.5mm] text-grey-500">
                        Supplier´s price
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {section?.is_in_house ? (
                    <>
                      {section?.items?.map((item) => (
                        <tr key={item.cost_estimate_item_id} className="border-b border-b-grey-100">
                          <td className="w-[97.5mm] py-[2mm] text-left text-[3.75mm] font-normal leading-[4.5mm]">
                            {item?.title}
                          </td>
                          <td className="w-[20mm] text-right text-[3.75mm] font-normal leading-[4.5mm]">
                            {item?.unit === 'hrs' ? (
                              <>
                                <PriceDisplay value={item?.number} currency={item?.unit} />.
                              </>
                            ) : (
                              <span>
                                <span className="text-[3.75mm] leading-[4.5mm]">{item?.number}</span>
                                <span className="ml-[1mm] text-[3mm] leading-[4mm]">{item?.unit}</span>
                              </span>
                            )}
                          </td>
                          <td className="w-[27.5mm] text-right text-[3.75mm] font-normal leading-[4.5mm]">
                            <PriceDisplay value={item?.unit_price} currency={costEstimate?.currency} />
                            <small className="text-[3mm] leading-[4mm]">{item?.unit === 'hrs' ? '/h' : ''}</small>
                          </td>
                          <td className="w-[35mm] text-right text-[3.75mm] font-normal leading-[4.5mm]">
                            <PriceDisplay value={item?.price} currency={costEstimate?.currency} />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {section?.items?.map((item) => (
                        <tr key={item.cost_estimate_item_id} className="border-b border-b-grey-100">
                          <td className="w-[97.5mm] py-[2mm] text-left text-[3.75mm] font-normal leading-[4.5mm]">
                            {item?.title}
                          </td>
                          <td className="w-[20mm] text-right text-[3.75mm] font-normal leading-[4.5mm]">
                            <PriceDisplay value={item?.price} currency={costEstimate?.currency} />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              <div className="flex flex-col items-end gap-[4mm] py-[6mm]">
                {section?.discount && (
                  <>
                    <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                      <div>Section subtotal:</div>
                      <div className="min-w-[42mm] text-right">
                        <PriceDisplay value={section?.amount} currency={costEstimate?.currency} />
                      </div>
                    </div>
                    <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                      <div>Discount: {section?.discount_unit?.trim() === '%' ? `${section.discount}%` : ''}</div>
                      <div className="min-w-[42mm] text-right">
                        <PriceDisplay
                          value={section?.amount_after_modifiers - section?.amount}
                          currency={costEstimate?.currency}
                        />
                      </div>
                    </div>
                  </>
                )}

                {section?.agency_fee && (
                  <>
                    <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                      <div>Section subtotal:</div>
                      <div className="min-w-[42mm] text-right">
                        <PriceDisplay value={section?.amount} currency={costEstimate?.currency} />
                      </div>
                    </div>
                    <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                      <div>{`Agency fee: ${section?.agency_fee}%`}</div>
                      <div className="min-w-[42mm] text-right">
                        <PriceDisplay
                          value={section?.amount_after_modifiers - section?.amount}
                          currency={costEstimate?.currency}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                  <div>Section total:</div>
                  <div className="min-w-[42mm] text-right text-[5.5mm]">
                    <PriceDisplay
                      value={section?.amount_after_modifiers}
                      currency={costEstimate?.currency}
                      largeIntegralPart
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <footer className="my-[4mm] px-[8mm]">
        <div className="flex flex-col bg-grey-50 px-[4mm] py-[6mm]">
          {costEstimate.discount && (
            <div className="mb-[6mm] flex flex-col items-end gap-[4mm] border-b border-b-neutral-300 pb-[6mm]">
              <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                <div>Subtotal:</div>
                <div className="min-w-[42mm] text-right">
                  <PriceDisplay value={costEstimate?.amount} currency={costEstimate?.currency} />
                </div>
              </div>
              <div className="flex gap-[2mm] text-[3.75mm] leading-[4.5mm]">
                <div>Discount: {costEstimate?.discount_unit?.trim() === '%' ? `${costEstimate.discount}%` : ''}</div>
                <div className="min-w-[42mm] text-right">
                  <PriceDisplay
                    value={costEstimate?.amount_after_discount - costEstimate?.amount}
                    currency={costEstimate?.currency}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col items-end gap-[4mm]">
            <div className="flex items-end gap-[2mm] text-[3.75mm] leading-[4.5mm]">
              <div>Total (excl. VAT):</div>
              <div className="min-w-[42mm] text-right">
                <PriceDisplay
                  value={costEstimate?.amount_after_discount}
                  currency={costEstimate?.currency}
                  largeIntegralPart
                  className="[&_span:first-child]:text-[8mm]"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="flex flex-col gap-[8mm] px-[12mm] py-[4mm]">
        {costEstimate?.note && (
          <div className="flex flex-col">
            <small className="text-[3mm] leading-[3.5mm] text-grey-500">Note:</small>
            <p className="py-[0.5mm] text-[3.75mm] leading-[4.5mm]">{costEstimate?.note}</p>
          </div>
        )}

        <div className="flex flex-col">
          <small className="text-[3mm] leading-[3.5mm] text-grey-500">Created by:</small>
          <p className="py-[0.5mm] text-[3.75mm] leading-[4.5mm]">{costEstimate?.project?.project_holder?.full_name}</p>
        </div>
      </div>
    </div>
  );
}
