/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateSectionData").CostEstimateSectionData} CostEstimateSectionData */

import InvisibleTextInput from 'components/inputs/InvisibleTextInput';
import { _t } from 'lang';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * The invisible input for the name of a section.
 *
 * @param {{
 *   data: CostEstimateSectionData,
 *   updateData: (update: Partial<CostEstimateSectionData>) => void,
 *   autoFocus?: boolean
 * }}
 */
export default function SectionName({ data, updateData, autoFocus = false }) {
  const [name, setName] = useState(data.name);

  // Debounce can take longer here because change in section name does not
  // cause changes in the price calculation.
  const [propagateChanges] = useState(() => debounce((name) => updateData({ name }), 300));

  useEffect(() => {
    propagateChanges(name);
  }, [name]);

  return (
    <InvisibleTextInput
      className="text-[20px] leading-[36px]"
      placeholder={_t('New section')}
      required
      value={name}
      onChange={setName}
      autoFocus={autoFocus}
    />
  );
}
