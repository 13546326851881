import CostEstimateName from 'pages/finance/cost-estimate/add-cost-estimate/sections/general-information/inputs/CostEstimateName';
import CostEstimateDescription from 'pages/finance/cost-estimate/add-cost-estimate/sections/general-information/inputs/CostEstimateDescription';
import CostEstimateClient from 'pages/finance/cost-estimate/add-cost-estimate/sections/general-information/inputs/CostEstimateClient';
import CostEstimatePriceList from 'pages/finance/cost-estimate/add-cost-estimate/sections/general-information/inputs/CostEstimatePriceList';
import { Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useState } from 'react';

/**
 * The general information section of a cost estimate.
 */
export default function GeneralInformationSection() {
  const [opened, setOpened] = useState(true);

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('GENERAL INFORMATION')} opened={opened} setOpened={setOpened} />
      <Collapse in={opened}>
        <Stack spacing={16} maw={790}>
          <CostEstimateClient />
          <CostEstimatePriceList />
          <CostEstimateName />
          <CostEstimateDescription />
        </Stack>
      </Collapse>
    </Stack>
  );
}
