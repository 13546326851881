/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-collection-get-time-summary-body').CostEstimateCollectionGetTimeSummaryBody} CostEstimateCollectionGetTimeSummaryBody
 */

/**
 * @typedef {import('./cost-estimate-collection-get-time-summary-response').CostEstimateCollectionGetTimeSummaryResponse} CostEstimateCollectionGetTimeSummaryResponse
 */

/**
 * The path of the CostEstimateCollectionGetTimeSummary action.
 */
export const costEstimateCollectionGetTimeSummaryPath = new SweetPath(
  '/api/finance/cost_estimate_collection/{cost_estimate_collection_id}/time_summary',
  { path: '{param}' }
);

/**
 * Creates a contextualized CostEstimateCollectionGetTimeSummary action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateCollectionGetTimeSummary(axios) {
  /**
   * The costEstimateCollectionGetTimeSummary action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CostEstimateCollectionGetTimeSummaryResponse>}
   */
  function costEstimateCollectionGetTimeSummary({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = costEstimateCollectionGetTimeSummaryPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return costEstimateCollectionGetTimeSummary;
}
