import FinanceStatusBubble from 'components/FinanceStatusBubble';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { Group, Menu } from '@mantine/core';
import { _t } from 'lang';
import EditIcon from 'components/icons/EditIcon';
import FinancialValueCell from 'pages/finance/finance-project-overview/cost-estimate-table/FinancialValueCell';
import { useState } from 'react';
import { Link } from 'react-router-dom';

/**
 * Displays each part of the Cost Estimate:
 *  - external costs
 *  - invoice
 *  - invoice request
 *
 * @param {{
 *   icon: React.ReactNode,
 *   name: string,
 *   currency: string,
 *   invoice: number,
 *   budget: number,
 *   externalCost: number,
 *   pagePath: string,
 *   editPath: string,
 * }}
 */
export default function FinanceTableRow({ icon, name, currency, invoice, budget, externalCost, pagePath, editPath }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  return (
    <div className="group grid h-12 grid-cols-[88px_1fr_128px_128px_128px_128px_24px] items-center gap-2 border-b border-b-neutral-100 pb-3 pl-2 pr-2 pt-3 text-neutral-700 hover:bg-neutral-20">
      <div></div> {/* drag and drop icon */}
      <Group maw={590}>
        <Link
          to={pagePath}
          className="fill-neutral-700 text-[15px] leading-[18px] transition-all hover:fill-hyperlink hover:text-hyperlink"
        >
          <div className="flex flex-row items-center gap-1 text-[15px] leading-[18px]">
            <div className="h-6 w-6">{icon}</div>
            <div>{name}</div>
          </div>
        </Link>
      </Group>
      {invoice ? <FinancialValueCell value={invoice} currency={currency} /> : <div></div>}
      {budget ? <FinancialValueCell value={budget} currency={currency} /> : <div></div>}
      {externalCost ? <FinancialValueCell value={externalCost} currency={currency} /> : <div></div>}
      {/* TODO the status value should not be hard-coded  */}
      <FinanceStatusBubble status={_t('Closed')} variant="small" />
      <div className={`flex justify-end ${menuClasses}`}>
        <Menu width={220} position="bottom-end" offset={15} onChange={setIsMenuOpened}>
          <Menu.Target>
            <div className="-my-2 cursor-pointer py-2">
              <OptionsDotsIcon />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<EditIcon fill="#4D4D4D" />} component={Link} to={pagePath}>
              {_t('View')}
            </Menu.Item>
            <Menu.Item icon={<EditIcon fill="#4D4D4D" />} component={Link} to={editPath}>
              {_t('Edit')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
}
