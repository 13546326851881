/** @typedef {import('pages/finance/cost-estimate/add-cost-estimate/data/AgencyFeeData').AgencyFeeData} AgencyFeeData */

import { _t } from 'lang';
import { Group, Menu } from '@mantine/core';
import { useState, useEffect } from 'react';
import CommentIcon from 'components/icons/CommentIcon';
import CommentModal from 'pages/finance/cost-estimate/add-cost-estimate/components/CommentModal';
import createAgencyFeeData from 'pages/finance/cost-estimate/add-cost-estimate/data/AgencyFeeData';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import useImmutable from 'hooks/use-immutable';
import MoneyInput from 'components/inputs/MoneyInput';
import CommentTooltip from 'components/comments/CommentTooltip';
import PriceDisplay from 'components/PriceDisplay';

/**
 * A form for adding a agency fee to a cost estimate.
 *
 * @param {{
 *   onChange: (data: AgencyFeeData) => void,
 *   onDelete: () => void,
 *   initialData?: Partial<AgencyFeeData>,
 *   feeAmount?: number,
 *   currency: string,
 * }}
 */
export default function AgencyFeeForm({ onChange, onDelete, initialData, feeAmount, currency }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isCommentModalOpened, setIsCommentModalOpened] = useState(false);
  const [data, updateData] = useImmutable(initialData, { createFn: createAgencyFeeData });

  /**
   * Prompts the user to add a comment.
   */
  function addComment() {
    setIsCommentModalOpened(true);
  }

  // Propagate changes to the parent component.
  // todo: add debounce
  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <div className="grid grid-cols-[auto_80px_168px_200px] items-center justify-end gap-2">
      <span className="text-[15px] leading-[18px]">{_t('Agency Fee:')}</span>

      <MoneyInput
        readOnly
        disabled
        styles={{ input: { textAlign: 'right' } }}
        min={0}
        currency="%" // todo: this works but maybe rename the prop to "unit" or something.
        value={data.amount}
        onChange={(amount) => updateData({ amount })}
      />

      <PriceDisplay className="text-right" value={feeAmount} currency={currency} />

      <Group position="right" spacing={8}>
        <div className="flex items-center justify-center">
          {data.comment && <CommentTooltip comment={data.comment} />}
        </div>

        <div className="flex items-stretch">
          <div className={isMenuOpened ? 'flex' : 'invisible group-hover:visible'}>
            <Menu width={210} offset={15} onChange={setIsMenuOpened}>
              <Menu.Target>
                <div className="flex cursor-pointer items-center justify-center">
                  <OptionsDotsIcon />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={onDelete} icon={<DeleteIcon />}>
                  <span>{_t('Remove Agency Fee')}</span>
                </Menu.Item>
                <Menu.Divider />
                {data.comment ? (
                  <>
                    <Menu.Item onClick={addComment} icon={<EditIcon />}>
                      <span>{_t('Edit Comment')}</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => updateData({ comment: null })} icon={<DeleteIcon />}>
                      <span>{_t('Remove Comment')}</span>
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item onClick={addComment} icon={<CommentIcon />}>
                    <span>{_t('Add Comment')}</span>
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </Group>

      <CommentModal
        opened={isCommentModalOpened}
        initialComment={data.comment}
        onClose={(comment) => {
          setIsCommentModalOpened(false);

          if (comment) {
            updateData({ comment });
          }
        }}
      />
    </div>
  );
}
