/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Project" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function ProjectIcon({ className = '', height = 24, width = 24, fill = '#999999' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5L9.35355 4.64645L9.20711 4.5H9V5ZM11 7L10.6464 7.35355L10.7929 7.5H11V7ZM16.5 10C16.5 10.2761 16.7239 10.5 17 10.5C17.2761 10.5 17.5 10.2761 17.5 10H16.5ZM3.5 17V7H2.5V17H3.5ZM5 5.5H9V4.5H5V5.5ZM8.64645 5.35355L10.6464 7.35355L11.3536 6.64645L9.35355 4.64645L8.64645 5.35355ZM11 7.5H15V6.5H11V7.5ZM15 7.5C15.8284 7.5 16.5 8.17157 16.5 9H17.5C17.5 7.61929 16.3807 6.5 15 6.5V7.5ZM3.5 7C3.5 6.17157 4.17157 5.5 5 5.5V4.5C3.61929 4.5 2.5 5.61929 2.5 7H3.5ZM2.5 17C2.5 18.3807 3.61929 19.5 5 19.5V18.5C4.17157 18.5 3.5 17.8284 3.5 17H2.5ZM16.5 9V10H17.5V9H16.5ZM7.5 12C7.5 11.1716 8.17157 10.5 9 10.5V9.5C7.61929 9.5 6.5 10.6193 6.5 12H7.5ZM9 10.5H19V9.5H9V10.5ZM19 10.5C19.8284 10.5 20.5 11.1716 20.5 12H21.5C21.5 10.6193 20.3807 9.5 19 9.5V10.5ZM20.5 12V17H21.5V12H20.5ZM20.5 17C20.5 17.8284 19.8284 18.5 19 18.5V19.5C20.3807 19.5 21.5 18.3807 21.5 17H20.5ZM19 18.5H5V19.5H19V18.5ZM5 19.5C6.38071 19.5 7.5 18.3807 7.5 17H6.5C6.5 17.8284 5.82843 18.5 5 18.5V19.5ZM7.5 17V12H6.5V17H7.5Z"
        fill={fill}
      />
    </svg>
  );
}
