import AddCostEstimatePageContent from 'pages/finance/cost-estimate/add-cost-estimate/AddCostEstimatePageContent';
import { ClientProvider } from 'providers/client/ClientProvider';
import { PriceListProvider } from 'providers/price-list/PriceListProvider';
import { JobSelectProvider } from 'pages/finance/cost-estimate/add-cost-estimate/providers/JobSelectProvider';
import { DataProvider, useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { SubmitProvider } from 'pages/finance/cost-estimate/add-cost-estimate/providers/SubmitProvider';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import AddCostEstimatePageChooseType from './AddCostEstimatePageChooseType';

/**
 * Form for adding a cost estimate.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=3329%3A51194&t=j4NS3BSXk6aY0J4C-4
 */
export default function AddCostEstimatePage() {
  return (
    <DashboardLayout contentClass="bg-legacy-elements-body px-8 pb-8">
      <ClientProvider>
        <PriceListProvider>
          <JobSelectProvider>
            <DataProvider>
              <SubmitProvider>
                <AddCostEstimatePageImpl />
              </SubmitProvider>
            </DataProvider>
          </JobSelectProvider>
        </PriceListProvider>
      </ClientProvider>
    </DashboardLayout>
  );
}

/**
 * Fork of AddCostEstimatePageContent that checks if the cost estimate ID is set.
 */
function AddCostEstimatePageImpl() {
  const { data } = useData();

  if (data.costEstimateId) {
    return <AddCostEstimatePageContent />;
  } else {
    return <AddCostEstimatePageChooseType />;
  }
}
