import { useInterval } from '@mantine/hooks';
import { useSubmit } from '../providers/SubmitProvider';
import { useCallback, useEffect, useState } from 'react';
import { COST_ESTIMATE_AUTO_SAVE_INTERVAL } from 'environment';

/**
 * Implements auto save functionality.
 */
export default function useAutoSave() {
  const { save } = useSubmit();
  const [saveToken, setSaveToken] = useState(0);
  const incSaveToken = useCallback(() => setSaveToken((token) => token + 1), []);
  const saveInterval = useInterval(incSaveToken, COST_ESTIMATE_AUTO_SAVE_INTERVAL);

  useEffect(() => {
    if (saveToken > 0) {
      save({ isDraft: true, withoutNotification: true });
    }
  }, [saveToken]);

  useEffect(() => {
    saveInterval.start();
    return saveInterval.stop;
  }, []);
}
