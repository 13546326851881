import ProgressBar from 'components/progress/ProgressBar';
import { round } from 'lodash';
import formatPrice from 'utils/format-price';

/**
 * Computes the value of the progress bar.
 *
 * @param {number} total
 * @param {number} part
 */
function computeValue(total, part) {
  if (total <= 0) {
    return part <= 0 ? 0 : Infinity;
  }

  const precision = part % total === 0 ? 2 : 0;
  const percent = Math.max(0, part / total) * 100;

  return round(percent, precision);
}

/**
 * Displays a progress bar group (with percentage and other number if provided).
 *
 * @see {@link https://www.figma.com/file/jZPNVAdhBmEl8qIOrILWmH/Toolio---component-library?node-id=498%3A5396&mode=dev Figma}
 *
 * @param {{
 *   variant: 'positive' | 'negative' | 'constant',
 *   total: number,
 *   part: number,
 *   currency?: string,
 *   showNumbers?: boolean,
 *   barHeight?: number,
 * }}
 */
export default function ProgressGroup({ variant, total, part, currency, showNumbers = false, barHeight = 4 }) {
  const remains = total - part;
  const value = computeValue(total, part);

  return (
    <div className="flex w-full flex-col">
      {showNumbers && (
        <div className="flex flex-row gap-1 text-[12px] leading-[16px] text-neutral-500">
          <span>{part}</span>
          <span>/</span>
          <span>{total}</span>
        </div>
      )}
      <div className="grid grid-cols-[1fr_35px]">
        <div className={`flex h-4 items-center justify-stretch ${isFinite(value) ? 'col-span-1' : 'col-span-2'}`}>
          <ProgressBar variant={variant} value={value} height={barHeight} />
        </div>
        {isFinite(value) && (
          <div className="whitespace-nowrap text-right text-[12px] leading-[16px] text-neutral-700">
            <span className="mr-[1px]">{value}</span>
            <span>%</span>
          </div>
        )}
      </div>
      {showNumbers && (
        <div className="flex text-[12px] leading-[16px] text-neutral-700">{formatPrice(remains, currency)}</div>
      )}
    </div>
  );
}
