/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { Select, Skeleton } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useJobSelect } from 'pages/finance/cost-estimate/add-cost-estimate/providers/JobSelectProvider';
import { _t } from 'lang';
import { CUSTOM_JOB_POSITION_ITEM_ID } from 'pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData';

/**
 * Input inside a CostEstimateRow to specify a job position.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 *   hasDifferentCurrency: boolean,
 * }}
 */
export default function JobPosition({ data, updateData, hasDifferentCurrency }) {
  const [jobPositionId, setJobPositionId] = useState(data.jobPositionId);
  const { jobData } = useJobSelect();

  // Propagate changes with debounce.
  useEffect(() => {
    if (jobData !== null) {
      const jobPositionName = jobData.find((job) => job.value === jobPositionId)?.label ?? '';

      updateData({
        jobPositionId,
        jobPositionName,
      });
    }
  }, [jobData, jobPositionId]);

  useEffect(() => {
    if (hasDifferentCurrency) {
      updateData({ jobPositionName: _t('Custom'), jobPositionId: CUSTOM_JOB_POSITION_ITEM_ID });
      setJobPositionId(CUSTOM_JOB_POSITION_ITEM_ID);
    }
  }, [hasDifferentCurrency]);

  if (jobData === null) {
    return <Skeleton w="100%" h={36} radius={8} />;
  }

  if (hasDifferentCurrency) {
    return (
      <Select
        data={[{ label: _t('Custom'), value: CUSTOM_JOB_POSITION_ITEM_ID }]}
        value={CUSTOM_JOB_POSITION_ITEM_ID}
        searchable
      />
    );
  }

  return <Select data={jobData} value={jobPositionId} onChange={setJobPositionId} searchable />;
}
