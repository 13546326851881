/** @typedef {'<' | '>'} Operator */

/**
 * @typedef {{
 *   operator: Operator,
 *   value: number,
 *   color: string,
 * }} Rule
 */

/**
 * Color scale for the ring indicators.
 */
export default class ColorScale {
  /** @type {Rule[]} */
  rules = [];
  defaultColor = 'green';

  /**
   * Set the default color.
   *
   * @param {string} color
   */
  setDefaultColor(color) {
    this.defaultColor = color;
    return this;
  }

  /**
   * Add a rule to the scale.
   *
   * @param {Operator} operator
   * @param {number} value
   * @param {string} color
   */
  addRule(operator, value, color) {
    this.rules.push({ operator, value, color });
    return this;
  }

  /**
   * Get the color for the given value.
   *
   * @param {number} value
   */
  getColor(value) {
    for (let i = 0; i < this.rules.length; i++) {
      const rule = this.rules[i];

      if (rule.operator === '<' && value < rule.value) {
        return rule.color;
      }

      if (rule.operator === '>' && value > rule.value) {
        return rule.color;
      }
    }

    return this.defaultColor;
  }

  /**
   * Convert the scale to a compact representation.
   *
   * @param {(string | number)[]} thresholds
   *
   * @returns {ColorScale}
   *
   * @example
   * const scale = ColorScale.fromCompact(['red', 1, 'yellow', 14, 'green']);
   */
  static fromCompact(thresholds) {
    const scale = new ColorScale();

    if (thresholds.length > 0) {
      scale.setDefaultColor(thresholds[thresholds.length - 1]);

      for (let i = 0; i < thresholds.length - 1; i += 2) {
        const value = thresholds[i + 1];
        const color = thresholds[i];

        if (typeof value === 'number') {
          scale.addRule('<', value, color);
        }
      }
    }

    return scale;
  }
}
