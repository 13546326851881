import { _t } from 'lang';
import PriceDisplay from 'components/PriceDisplay';
import CostEstimatePart from 'pages/finance/cost-estimate/detail/cost-estimate-tab/CostEstimatePart';
import ProjectInformation from 'pages/finance/cost-estimate/detail/cost-estimate-tab/ProjectInformation';
import CommentTooltip from 'components/comments/CommentTooltip';
import CostEstimateState from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/CostEstimateState';
import CostEstimateFinancialSummary from 'pages/finance/cost-estimate/detail/cost-estimate-tab/CostEstimateFinancialSummary';
import CostEstimateAlerts from 'pages/finance/cost-estimate/detail/cost-estimate-tab/CostEstimateAlerts';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import CostEstimateActions from 'pages/finance/cost-estimate/detail/CostEstimateActions';
import CostEstimateComments from 'pages/finance/cost-estimate/detail/cost-estimate-tab/CostEstimateComments';

/**
 * The tab with the cost estimate data in the cost estimate detail page.
 */
export default function CostEstimateTab() {
  const { costEstimate } = useCostEstimateDetail();

  return (
    <div>
      <CostEstimateState />
      <CostEstimateFinancialSummary />

      <div className="px-8">
        <CostEstimateAlerts />
        <ProjectInformation />

        {costEstimate.collections.map((collection, i) => (
          <CostEstimatePart
            key={collection.collection_id}
            currency={costEstimate.currency}
            sections={collection.sections}
            title={_t('COST ESTIMATE (COLLECTION %s)', i + 1)}
          />
        ))}

        {/* Total */}
        <div className="mt-4 flex flex-col items-end justify-center px-4 py-6">
          {costEstimate.discount > 0 && (
            <div className="flex w-full flex-col gap-4 border-b border-b-neutral-300 pb-6">
              <div className="grid h-[18px] grid-cols-[auto_168px_160px] items-center justify-end gap-2 text-right text-[15px] leading-[18px]">
                <span>{_t('Subtotal:')}</span>
                <PriceDisplay value={costEstimate.amount} currency={costEstimate.currency} />
              </div>
              <div className="grid grid-cols-[auto_168px_160px] items-center justify-end gap-2 text-right text-[15px] leading-[18px]">
                <span>
                  <span>{_t('Discount:')}</span>
                  {costEstimate.discount_unit?.trim() === '%' && <span> {costEstimate.discount}%</span>}
                </span>
                <PriceDisplay
                  value={costEstimate.amount_after_discount - costEstimate.amount}
                  currency={costEstimate.currency}
                />
                {costEstimate.discount_comment && (
                  <div className="flex items-center justify-end">
                    <CommentTooltip className="pl-2" comment={costEstimate.discount_comment} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={`grid h-[52px] grid-cols-[auto_168px_120px] items-baseline justify-end gap-x-2 pr-10 text-right ${
              costEstimate.discount > 0 ? 'pt-6' : ''
            }`}
          >
            <span className="text-[15px] leading-[18px]">{_t('Total (excl. VAT):')}</span>
            <PriceDisplay size="xl" value={costEstimate.amount_after_discount} currency={costEstimate.currency} />
            <PriceDisplay
              className="text-complementary-african-violet"
              value={costEstimate.external_cost_sum_estimated}
              currency={costEstimate.currency}
            />
          </div>
        </div>

        {/* Note */}
        {costEstimate.note && (
          <div className="pt-4">
            <div className="flex flex-col">
              <div className="text-[12px] leading-[24px] text-neutral-500">{_t('Note:')}</div>
              <div className="py-1 text-[15px] leading-[18px] text-neutral-700">{costEstimate.note}</div>
            </div>
          </div>
        )}

        {/* Buttons */}
        <CostEstimateActions tab="cost-estimate" className="mt-4 py-4" />

        {/* Comments */}
        <CostEstimateComments />
      </div>
    </div>
  );
}
