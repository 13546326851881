import FormRow from 'components/forms/FormRow';
import { _t } from 'lang';
import { useClient } from 'providers/client/ClientProvider';
import { usePriceList } from 'providers/price-list/PriceListProvider';
import PriceListSelect from 'components/selects/PriceListSelect';
import { useMemo } from 'react';

/**
 * The client of a cost estimate.
 */
export default function CostEstimatePriceList() {
  const { clientId } = useClient();
  const { priceListId, setPriceListId } = usePriceList();

  const filter = useMemo(() => ({ 'client_id.eq': clientId }), [clientId]);

  return (
    <FormRow
      label={_t('Price list')}
      input={
        <PriceListSelect
          value={String(priceListId)}
          onChange={(value) => setPriceListId(Number(value))}
          actionFilter={filter}
        />
      }
    />
  );
}
