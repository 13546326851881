import React from 'react';
import AppRoutes from 'routes/AppRoutes';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import ResumeSessionModal from 'components/modals/ResumeSessionModal';
import ApiProvider from 'api/ApiProvider';
import FileManagerProvider from 'api/file-manager/FileManagerProvider';
import theme from 'theme';
import { ConfirmProvider } from 'providers/confirm/ConfirmProvider';
import { TimeLogProvider } from 'pages/time-entries/time-log/TimeLogProvider';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  GOOGLE_TAG_MANAGER_ID,
} from 'environment';
import { SearchProvider } from 'layouts/dashboard-layout/headers/search/SearchProvider';
import { DeactivateProvider } from 'providers/deactivate-user/DeactivateUserProvider';
import { TimerProvider } from 'components/timer/TimerProvider';
import { PickTaskSubtaskProvider } from 'providers/pick-task-subtask/PickTaskSubtaskProvider';
import { VersionProvider } from 'version/VersionProvider';
import TagManager from 'react-gtm-module';

// Firebase
if (
  FIREBASE_API_KEY &&
  FIREBASE_AUTH_DOMAIN &&
  FIREBASE_PROJECT_ID &&
  FIREBASE_STORAGE_BUCKET &&
  FIREBASE_MESSAGING_SENDER_ID &&
  FIREBASE_APP_ID
) {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  const firebase = initializeApp(firebaseConfig);
  getPerformance(firebase);
}

// Google Tag Manager
if (GOOGLE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
  });
}

/**
 * The main application component. All providers and global components (modals,
 * notifications, etc.) should be registered here.
 *
 * The providers are:
 *   - MantineProvider   Provider for the Mantine components library
 *   - AuthProvider      Provides authentication services
 *
 * @returns {React.Component} The application component.
 */
export default function App() {
  return (
    <BrowserRouter>
      <MantineProvider withGlobalStyles withCSSVariables withNormalizeCSS theme={theme}>
        <NotificationsProvider position="bottom-left">
          <VersionProvider>
            <ApiProvider>
              <TimeLogProvider>
                <TimerProvider>
                  <SearchProvider>
                    <ConfirmProvider>
                      <DeactivateProvider>
                        <PickTaskSubtaskProvider>
                          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                            <FileManagerProvider>
                              <ModalsProvider modals={{ resumeSession: ResumeSessionModal }}>
                                <AppRoutes />
                              </ModalsProvider>
                            </FileManagerProvider>
                          </DndProvider>
                        </PickTaskSubtaskProvider>
                      </DeactivateProvider>
                    </ConfirmProvider>
                  </SearchProvider>
                </TimerProvider>
              </TimeLogProvider>
            </ApiProvider>
          </VersionProvider>
        </NotificationsProvider>
      </MantineProvider>
    </BrowserRouter>
  );
}
