/** @typedef {import("pages/finance/cost-estimate/add-cost-estimate/data/CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { Select } from '@mantine/core';
import { useEffect, useState } from 'react';

/**
 * Options of the Unit select.
 */
const SELECT_OPTIONS = [
  { label: 'hrs.', value: 'hrs' },
  { label: 'pcs.', value: 'pcs' },
];

/**
 * Input inside a CostEstimateRow to specify a unit.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function Unit({ data, updateData }) {
  const [unit, setUnit] = useState(data.unit);

  // Set unit to hours when the job position is changed.
  useEffect(() => {
    if (!data.isCustomItem) {
      setUnit('hrs');
    }
  }, [data.isCustomItem]);

  // Propagate changes with debounce.
  useEffect(() => {
    updateData({ unit });
  }, [unit]);

  return <Select data={SELECT_OPTIONS} value={unit} disabled={!data.isCustomItem} onChange={setUnit} />;
}
